<template>
  <div>
    <loading
      v-model:active="isLoading"
      :is-full-page="fullPage"
      color="#007070"
    ></loading>

    <div>
      <div class="d-sm-flex align-items-center justify-content-between mg-b-10">
        <div class="mg-b-10">
          <h5 class="mg-b-0">อัพโหลดออเดอร์</h5>
        </div>
        <div class="d-block"></div>
      </div>

      <div class="row row-xs mg-md-t-10">
        <!-- col -->
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 mg-t-10 mg-lg-t-0">
          <div class="row row-xs">
            <!-- col -->
            <div
              class="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-10 mg-lg-t-0"
            >
              <div class="card">
                <div class="card-body">
                  <ul class="nav nav-line" role="">
                    <li class="nav-item">
                      <a class="nav-link cursor-pointer active">อัพโหลด</a>
                    </li>
                  </ul>
                  <div class="tab-content">
                    <div class="tab-pane fade show active mg-t-20">
                      <div>
                        <div class="form-row">
                          <div class="form-group col-md-12">
                            <label for="inputAddress"
                              >กรุณาเลือกไฟล์ที่ต้องการอัพโหลด
                              <span class="tx-danger">*</span></label
                            >
                            <div class="input-group">
                              <div class="custom-file">
                                <input
                                  type="file"
                                  class="custom-file-input"
                                  id="inputGroupFile01"
                                  aria-describedby="inputGroupFileAddon01"
                                  @change="onFileChange"
                                />
                                <label
                                  class="custom-file-label tx-roboto"
                                  for="inputGroupFile01"
                                >
                                  {{
                                    file_upload_name === ""
                                      ? "คลิกเพื่อเลือกไฟล์"
                                      : file_upload_name
                                  }}
                                </label>
                              </div>
                              <div class="input-group-append">
                                <button
                                  class="btn btn-primary btn-uppercase"
                                  @click="doUpload()"
                                >
                                  ตรวจสอบ
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul class="nav nav-line" role="" v-if="dFile">
                    <li class="nav-item">
                      <a class="nav-link cursor-pointer active">ข้อมูลในไฟล์</a>
                    </li>
                  </ul>
                  <div v-if="dFile">
                    <pre class="language-html" v-html="file_content"></pre>
                  </div>
                  <ul class="nav nav-line" role="" v-if="dView">
                    <li class="nav-item">
                      <a class="nav-link cursor-pointer active"
                        >ตรวจสอบข้อมูล</a
                      >
                    </li>
                  </ul>
                  <div class="table-responsive mg-t-20" v-if="dView">
                    <table class="table table-sm table-hover">
                      <thead>
                        <tr class="nowrap">
                          <!-- <td>ลำดับ</td> -->
                          <td>อินวอยซ์</td>
                          <td>รายการที่</td>
                          <td colspan="2">ออเดอร์</td>

                          <td>ไซส์ยาง</td>
                          <td colspan="2">กรุณาเลือก</td>
                          <td>ตรวจสอบสต๊อก</td>
                          <td>ประเภท</td>
                          <td>จำนวนยาง</td>

                          <td colspan="2">ข้อกำหนด</td>
                          <td>แผนโหลด</td>
                        </tr>
                      </thead>
                      <tbody class="tx-roboto">
                        <tr
                          v-for="(b, index) in dTable"
                          :key="index"
                          class="nowrap"
                        >
                          <!-- <td>{{ parseInt(index) + 1 }}</td> -->
                          <td>
                            {{
                              b.SI_NO.substr(0, 5) === "00150"
                                ? b.SI_NO.substr(2, 8)
                                : b.SI_NO.substr(0, 4) !== "00TR"
                                ? b.SI_NO.substr(2, 6) +
                                  "-" +
                                  b.SI_NO.substr(8, 2)
                                : b.SI_NO.substr(2, 8)
                            }}
                          </td>
                          <td>{{ parseInt(b.SI_LINE_NO) / 10 }}</td>
                          <td>{{ b.ORDER_NO }}</td>
                          <td>{{ b.ORDER_LINE_NO }}</td>
                          <td>
                            {{
                              b.SIZE_SET_CODE === "3"
                                ? b.SIZE_SALE_CODE
                                : b.SIZE_SALE_CODE + b.SIZE_SET_CODE
                            }}
                          </td>
                          <td>
                            <select
                              class="form-control input-sm input_w"
                              v-model="b.PRODUCT_CODE_SELECT"
                            >
                              <option
                                v-for="(s, i) in b.PRODUCT_CODE"
                                :value="s"
                                :key="i"
                              >
                                {{ s }}
                              </option>
                            </select>
                          </td>
                          <td>
                            <button
                              class="btn btn-xs btn-icon btn-dark mg-r-2 pd-y-0 pd-x-1"
                              @click="popup(b.SIZE_SALE_CODE, b.SIZE_SET_CODE)"
                            >
                              <vue-feather
                                type="search"
                                class="mg-t-3"
                              ></vue-feather>
                            </button>
                          </td>
                          <td class="tx-indigo">
                            {{ b.PRODUCT_CODE_SELECT }}
                          </td>
                          <td>
                            {{ b.STOCK_TYPE }}
                          </td>
                          <td align="right">
                            {{ b.SHIP_QTY }}
                          </td>
                          <td class="tx-pink tx-bolder">
                            {{ b.REQ1 }}
                          </td>
                          <td class="tx-pink tx-bolder">
                            {{ b.REQ2 }}
                          </td>
                          <td>
                            {{ b.PLAN_DATE }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-if="dView" class="mg-t-10">
                    <button
                      id="btn-approve"
                      class="btn btn-success btn-uppercase mg-r-4"
                      @click="doSave"
                    >
                      บันทึกข้อมูล
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- col -->
            <!-- col -->
          </div>
          <!-- row -->
        </div>

        <!-- col -->
      </div>
      <!-- row -->
    </div>
  </div>
  <div
    id="mailCompose"
    class="mail-compose"
    :class="[popupOpen ? ' show ' : ' d-none', popupShrink ? ' shrink ' : '']"
    v-if="popupOpen"
  >
    <div class="mail-compose-dialog">
      <div class="mail-compose-header">
        <h4 class="mail-compose-title tx-white fw-bold mg-l-10 tx-15">
          {{ popupCode }}
        </h4>
        <nav class="nav nav-icon-only">
          <a
            id="mailComposeClose"
            href="#"
            @click="(popupOpen = false), (popupShrink = false)"
            class="nav-link nav-link-close"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line></svg
          ></a>
        </nav>
      </div>
      <!-- mail-compose-header -->
      <div class="mail-compose-body h100vh">
        <div class="row no-gutters rounded">
          <!-- col -->
          <div class="col-12 bg-white">
            <div class="ht-100p justify-content-centerx">
              <div class="table-responsive mg-t-20">
                <table class="table table-sm" v-if="popupData !== null">
                  <tbody class="tx-roboto">
                    <tr
                      v-for="(s, index) in popupData"
                      :key="index"
                      class="nowrap"
                    >
                      <td colspan="10">
                        <b>{{ s.size }}</b
                        ><br />
                        <table class="table table-xs tx-roboto">
                          <thead>
                            <tr class="tx-thai">
                              <th class="fit" align="center">ลำดับ</th>
                              <th class="fit" align="center">แอดเดรส</th>
                              <th class="fit" align="center">ล็อค</th>
                              <th class="fit" align="center">เก่าสุด</th>
                              <th class="fit" align="center">ใหม่สุด</th>
                              <th class="fit" align="center">ทั้งหมด</th>
                              <th class="fit" align="center">จอง</th>
                              <th class="fit" align="center">ล็อค</th>
                              <th class="fit" align="center">คงเหลือ</th>
                              <th align="center">หมายเหตุ</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(s2, index2) in s.listAddress"
                              :key="index2"
                              class="nowrap"
                            >
                              <td align="right">{{ index2 + 1 }}</td>
                              <td
                                :class="
                                  s2.Address_Hold_Flag === 'T'
                                    ? 'tx-danger line-through'
                                    : ''
                                "
                              >
                                {{ s2.Address_Code }}
                              </td>
                              <td
                                class="tx-bold"
                                :class="
                                  s2.Address_Hold_Flag === 'T'
                                    ? 'tx-danger'
                                    : 'tx-success'
                                "
                              >
                                {{ s2.Address_Hold_Flag === "T" ? "Y" : "N" }}
                              </td>
                              <td>{{ s2.First_In_Date }}</td>
                              <td>{{ s2.Last_In_Date }}</td>
                              <td align="right">{{ s2.Total_Qty_Balance }}</td>
                              <td align="right">{{ s2.Allocate_Quantity }}</td>
                              <td
                                align="right"
                                :class="
                                  s2.Address_Hold_Flag === 'T'
                                    ? 'tx-bold tx-danger'
                                    : ''
                                "
                              >
                                {{
                                  s2.Address_Hold_Flag === "T"
                                    ? s2.Total_Qty_Balance
                                    : 0
                                }}
                              </td>
                              <td align="right" class="tx-bold">
                                {{
                                  s2.Address_Hold_Flag === "T"
                                    ? 0
                                    : s2.Available_Quantity
                                }}
                              </td>

                              <td class="tx-danger">{{ s2.Remark }}</td>
                            </tr>
                            <tr>
                              <td colspan="5"></td>
                              <td align="right" class="tx-bold">
                                {{ s.totalQty[0] }}
                              </td>
                              <td align="right" class="tx-bold">
                                {{ s.totalQty[1] }}
                              </td>
                              <td
                                align="right"
                                class="tx-bold"
                                :class="
                                  parseInt(s.totalQty[3]) > 0 ? 'tx-danger' : ''
                                "
                              >
                                {{ s.totalQty[3] }}
                              </td>
                              <td align="right" class="tx-bold">
                                {{ s.totalQty[2] }}
                              </td>

                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <button
                  @click="popupOpen = false"
                  class="th btn btn-dark btn-uppercase mg-r-4"
                >
                  ปิดหน้าต่าง
                </button>
              </div>
            </div>
          </div>
          <!-- col -->
        </div>
      </div>
      <!-- mail-compose-body -->
    </div>
    <!-- mail-compose-dialog -->
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { useToast } from "vue-toastification";
//import $ from "jquery";
import axios from "axios";
import configs from "@/config/config";
import feather from "feather-icons";
//import moment from "moment";
const icons = Object.keys(feather.icons);
export default {
  data() {
    return {
      icons,
      isLoading: false,
      fullPage: true,
      dataLastUpdate: "",
      currentTab: "00",
      nStep: [0, 0, 0, 0, 0],
      dTable: null,
      dView: false,
      dFile: false,
      file_upload: null,
      file_upload_name: "",
      file_upload_url: "",
      file_content: "",
      popupOpen: false,
      popupShrink: false,
      popupData: null,
      popupCode: "",
    };
  },
  service: null,
  components: {
    Loading,
  },
  setup() {
    // Get toast interface
    const toast = useToast();
    // Make it available inside methods
    return { toast };
  },
  computed: {
    currentUser() {
      return this.$store.state.authen.user;
    },
    loggedIn() {
      return this.$store.state.authen.status.loggedIn;
    },
    currentPath() {
      return this.$route.path;
    },
    prefix() {
      return this.$store.state.prefix;
    },
  },
  async created() {
    if (
      this.prefix === "" ||
      this.prefix === null ||
      this.prefix === undefined
    ) {
      window.location.replace("/");
    }
  },
  async mounted() {},
  methods: {
    async popup(salecode, setcode) {
      this.popupData = null;
      this.popupCode = salecode + setcode;
      this.popupOpen = true;
      this.isLoading = true;
      console.log("popupData = ", this.popupData);
      await axios
        .get(configs.urlApi + "/" + this.prefix.toLowerCase() + "/master", {
          params: {
            action: "stock-balance",
            salecode: salecode + setcode,
          },
        })
        .then((response) => {
          console.log(response.data);
          let data = response.data;
          this.popupData = data.stock;
          this.isLoading = false;
        });
      console.log(salecode + setcode);
      setTimeout(() => {
        this.isLoading = false;
      }, 300);
    },

    onFileChange(e) {
      this.file_upload = e.target.files[0];
      this.file_upload_name = e.target.files[0].name;
      this.file_upload_url = URL.createObjectURL(this.file_upload);
    },
    async doUpload() {
      let isError = false;
      if (
        this.file_upload === null ||
        this.file_upload === "" ||
        this.file_upload === undefined
      ) {
        this.toast.error("กรุณาเลือกไฟล์ออเดอร์ที่ต้องการอัพโหลด", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: false,
          rtl: false,
        });
        isError = true;
      }
      if (!isError) {
        let formData = new FormData();
        formData.append("file_upload", this.file_upload);
        formData.append("prefix", this.prefix.toLowerCase());
        formData.append("action", "import-outbound-order-verify");
        const axiosConfig = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        await axios
          .post(
            configs.urlApi + "/" + this.prefix.toLowerCase() + "/outbound",
            formData,
            axiosConfig
          )
          .then((res) => {
            if (res.data.isSuccess === 1) {
              console.log(res.data);
              this.dFile = false;
              this.dView = true;
              this.dTable = res.data.orderContent;
            } else {
              if (res.data.fileContent) {
                this.dFile = true;
                this.file_content = res.data.fileContent;
              }

              this.toast.error(res.data.uploadResult, {
                position: "top-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: false,
                rtl: false,
              });
            }
          });

        //console.log(this.file_upload);
      }
    },
    async doSave() {
      let isError = false;

      for (const [key, item] of Object.entries(this.dTable)) {
        if (
          !item.PRODUCT_CODE_SELECT ||
          item.PRODUCT_CODE_SELECT === "" ||
          item.PRODUCT_CODE_SELECT === null
        ) {
          isError = true;
          this.toast.error(
            "(" +
              key +
              ") กรุณาเลือกไซส์สำหรับ " +
              item.SIZE_SALE_CODE +
              item.SIZE_SET_CODE,
            {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: false,
              rtl: false,
            }
          );
        }
      }
      if (!isError) {
        let strSubmit = "";

        for (const [key, item] of Object.entries(this.dTable)) {
          strSubmit =
            strSubmit +
            "|" +
            key +
            ":" +
            item.CUSTOMER_CODE +
            ":" +
            item.ORDER_LINE_NO +
            ":" +
            item.ORDER_NO +
            ":" +
            item.PLAN_DATE +
            ":" +
            item.PRODUCT_CODE_SELECT +
            ":" +
            item.REQ1 +
            ":" +
            item.REQ2 +
            ":" +
            item.SHIP_QTY +
            ":" +
            item.SIZE_DESCRIPTION +
            ":" +
            item.SIZE_SALE_CODE +
            ":" +
            item.SIZE_SET_CODE +
            ":" +
            item.SI_LINE_NO +
            ":" +
            item.SI_NO +
            ":" +
            item.STOCK_TYPE;
        }
        let formData = new FormData();
        formData.append("submit_data", strSubmit);
        formData.append("user", this.currentUser);
        formData.append("action", "import-outbound-order");
        axios
          .post(
            configs.urlApi + "/" + this.prefix.toLowerCase() + "/outbound",
            formData
          )
          .then((res) => {
            console.log(res.data);
            if (res.data.isSuccess === 1) {
              this.toast.success("บันทึกข้อมูลสำเร็จ", {
                position: "top-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: false,
                rtl: false,
              });
              this.file_upload = null;
              this.file_upload_name = "";
              this.file_upload_url = "";
              this.file_content = "";
              this.dTable = [];
              this.dView = false;
            } else {
              this.toast.error(res.data.txtError, {
                position: "top-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: false,
                rtl: false,
              });
            }
          });
      }
    },
  },
};
</script>
<style scoped>
.input_w {
  width: 100px;
  text-align: left;
  padding: 0px 8px;
  height: 22px;
}

.custom-file-input ~ .custom-file-label::after {
  content: "";
  display: none;
}
</style>
