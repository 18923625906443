<template>
  <div>
    <loading
      v-model:active="isLoading"
      :is-full-page="fullPage"
      color="#cd8444"
    ></loading>
    <div class="">
      <div class="row row-xs">
        <!-- col -->
        <div
          class="col-12 col-md-12 col-lg-10 col-xl-8 col-xxl-6 mg-t-10 mg-lg-t-0"
        >
          <div class="row row-xs">
            <!-- col -->
            <div
              class="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-10 mg-lg-t-0"
            >
              <div class="card">
                <div
                  class="bd-b-0 pd-t-20 pd-lg-t-20 pd-l-20 pd-lg-l-20 d-flex flex-column flex-sm-row align-items-sm-start justify-content-sm-between"
                >
                  <div>
                    <h4 class="mg-b-5 text-primary tx-bold">
                      รายงานที่เกี่ยวกับงานโหลด
                    </h4>
                    <p class="mg-b-0">
                      กรุณาเลือกประเภทรายงานและระบุช่วงเวลาในการออกรายงาน
                    </p>
                  </div>
                </div>
                <div class="card-body">
                  <div>
                    <div class="tab-content">
                      <div class="tab-pane fade show active mg-t-20">
                        <div>
                          <div class="form-row">
                            <div class="form-group col-md-12">
                              <label for=""
                                >ประเภทรายงาน
                                <span class="tx-danger">*</span></label
                              >
                              <select class="form-control" v-model="rType">
                                <option value="">กรุณาเลือกประเภทรายงาน</option>
                                <optgroup label="การตัดสต๊อก">
                                  <option value="reports/report-201">
                                    รายงานการตัดสต๊อกยาง (ยอดรวมรายวัน/กะทำงาน)
                                  </option>
                                  <option value="reports/report-202">
                                    รายงานการตัดสต๊อกยาง (แบบรายละเอียด)
                                  </option>
                                </optgroup>
                                <optgroup label="การติดลาเบล">
                                  <option value="reports/report-204">
                                    ใบรายงานการทำงานส่วนงานติดลาเบล (ผู้รับเหมา)
                                    ยอดรวม
                                  </option>
                                  <option value="reports/report-203">
                                    ใบรายงานการทำงานส่วนงานติดลาเบล (ผู้รับเหมา)
                                    ประจำวัน
                                  </option>
                                </optgroup>

                                <!-- <optgroup label="แผนโหลดยาง">
                                  <option value="reports/report-203">
                                    ตารางแผนการโหลดยางประจำวัน (--- กำลังทำ ---)
                                  </option>
                                  <option value="reports/report-204">
                                    ตารางแผนการจัดเตรียมยางประจำวัน (--- กำลังทำ
                                    ---)
                                  </option>
                                </optgroup> -->
                              </select>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="form-group col-md-6">
                              <label for=""
                                >ช่วงวันที่ (เริ่มต้น)
                                <span class="tx-danger">*</span></label
                              >
                              <input
                                type="date"
                                class="form-control"
                                v-model="dStart"
                              />
                            </div>

                            <div class="form-group col-md-6">
                              <label for=""
                                >ถึงวันที่
                                <span class="tx-danger">*</span></label
                              >
                              <input
                                type="date"
                                class="form-control"
                                v-model="dEnd"
                              />
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="form-group col-md-6">
                              <button
                                class="th btn btn-dark btn-uppercase mg-r-4"
                                @click="downloadReport()"
                              >
                                ดาวน์โหลดรายงาน
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- row -->
            </div>
          </div>
        </div>
        <div class="mg-t-20"></div>
      </div>
    </div>

    <!-- col -->
  </div>
  <!-- row -->
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { useToast } from "vue-toastification";
import configs from "@/config/config";
import moment from "moment";
export default {
  data() {
    return {
      isLoading: false,
      fullPage: true,
      sitePrefix: "",
      rType: "",
      dStart: null,
      dEnd: null,
      timer: "",
      filterStatus: [],
    };
  },
  service: null,
  components: {
    Loading,
  },
  setup() {
    // Get toast interface
    const toast = useToast();

    // Make it available inside methods
    return { toast };
  },
  computed: {
    currentUser() {
      return this.$store.state.authen.user;
    },
    loggedIn() {
      return this.$store.state.authen.status.loggedIn;
    },
    currentPath() {
      return this.$route.path;
    },
    prefix() {
      return this.$store.state.prefix;
    },
  },
  created() {
    if (
      this.prefix === "" ||
      this.prefix === null ||
      this.prefix === undefined
    ) {
      window.location.replace("/");
    }
    this.dStart = moment().format("YYYY-MM-01");
    this.dEnd = moment().format("YYYY-MM-DD");
  },
  async mounted() {
    this.sitePrefix = await this.prefix;
  },
  methods: {
    downloadReport() {
      let isError = false;
      if (this.rType === null || this.rType === "") {
        this.toast.error("กรุณาเลือกประเภทรายงาน", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: false,
          rtl: false,
        });
        isError = true;
      }

      if (this.dStart === null || this.dStart === "") {
        this.toast.error("กรุณาระบุวันที่เริ่มต้น", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: false,
          rtl: false,
        });
        isError = true;
      }
      if (this.dEnd === null || this.dEnd === "") {
        this.toast.error("กรุณาระบุวันที่สิ้นสุด", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: false,
          rtl: false,
        });
        isError = true;
      }

      if (!isError) {
        let url =
          configs.urlApi +
          "?action=" +
          this.prefix.toLowerCase() +
          "/" +
          this.rType.toLowerCase() +
          "&prefix=" +
          this.prefix +
          "&dStart=" +
          this.dStart +
          "&dEnd=" +
          this.dEnd;
        console.log(url);
        window.open(url);
      }
    },
  },
};
</script>
<style scoped></style>
