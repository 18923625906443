<template>
  <div>
    <loading
      v-model:active="isLoading"
      :is-full-page="fullPage"
      color="#007070"
      background-color="#000"
    ></loading>

    <div>
      <div class="">
        <div class="">
          <h4 class="">ยินดีต้อนรับ</h4>
          <hr />
          <label
            class="d-block tx-medium tx-uppercase tx-spacing-1 tx-color-03 mg-b-15"
            >กรุณาเลือกโมดูลที่ต้องการใช้งาน</label
          >
          <div class="row">
            <div
              v-for="(m, index) in module"
              :key="index"
              class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 mg-b-20 module"
              @click="setPrefix(m.MODULE_CODE, m.MODULE_PATH)"
            >
              <div
                class="module-box bd pd-30 ht-sm-180 d-flex flex-column rounded shadow-sm"
              >
                <div class="mg-b-15">
                  <vue-feather
                    :type="m.MODULE_ICON"
                    class="wd-50 ht-50 tx-primary"
                  ></vue-feather>
                </div>
                <h5 class="tx-inverse mg-b-10">{{ m.MODULE_NAME }}</h5>
                <p class="mg-b-20 tx-color-03">
                  {{ m.MODULE_DESCRIPTION }}
                </p>
              </div>
            </div>
            <!-- col-6 -->
          </div>
          <!-- row -->
        </div>
      </div>
      <!-- row -->
    </div>
  </div>
</template>
<script>
import axios from "axios";
import configs from "../config/config";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { useToast } from "vue-toastification";
import feather from "feather-icons";
const icons = Object.keys(feather.icons);

export default {
  data() {
    return {
      icons,
      isLoading: false,
      fullPage: true,
      module: null,
    };
  },
  service: null,
  components: {
    Loading,
  },
  setup() {
    // Get toast interface
    const toast = useToast();
    // Make it available inside methods
    return { toast };
  },
  computed: {
    currentUser() {
      return this.$store.state.authen.user;
    },
    loggedIn() {
      return this.$store.state.authen.status.loggedIn;
    },
    currentPath() {
      return this.$route.path;
    },
    prefix() {
      return this.$store.state.prefix;
    },
  },
  created() {
    this.setPrefix(null);
  },
  mounted() {
    this.isLoading = false;
    this.getModules();
  },
  methods: {
    async getModules() {
      this.isLoading = true;
      await axios
        .get(configs.urlApi + "/master/get-modules", {
          params: {
            user: this.currentUser,
          },
        })
        .then((response) => {
          let data = response.data;
          console.log(data);
          this.module = data.module;
        });
      this.isLoading = false;
    },
    setPrefix(prefix, path) {
      if (prefix) {
        this.$store.dispatch("setPrefix", prefix);
        // this.$router.push(path + "/index");
        window.location.replace(path + "/index");
      } else {
        this.$store.dispatch("setPrefix", null);
      }
    },
  },
};
</script>
<style scoped>
.module {
  cursor: pointer;
}

.module .module-box {
  background: #fff;
}
.module .module-box svg {
  color: #007070;
}
.module:hover .module-box {
  border-bottom: 5px solid #007070;
}
.module:hover .module-box svg {
  color: #007070;
}
</style>
