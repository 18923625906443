<template>
  <div>
    <loading
      v-model:active="isLoading"
      :is-full-page="fullPage"
      color="#007070"
    ></loading>

    <div>
      <div class="d-sm-flex align-items-center justify-content-between mg-b-10">
        <div class="mg-b-10">
          <h5 class="mg-b-0">
            รายการโหลดของวันที่
            <b class="tx-primary">{{ inputDateStart }}</b> ถึงวันที่
            <b class="tx-primary">{{ inputDateEnd }}</b>
          </h5>
          <p class="tx-color-03 mg-b-0">
            <span>อัพเดทล่าสุด:</span> {{ dataLastUpdate }}
          </p>
        </div>
        <div class="d-block">
          <div class="input-group">
            <input type="date" class="form-control" v-model="inputDateStart" />
            <input type="date" class="form-control" v-model="inputDateEnd" />
            <button
              type="button"
              class="btn btn-primary btn-icon btn--group"
              style="border-top-right-radius: 0; border-bottom-right-radius: 0"
              @click="refreshData()"
            >
              <vue-feather type="search"></vue-feather>
            </button>
            <button
              type="button"
              class="btn btn-success btn-icon btn--group"
              style="border-top-right-radius: 0; border-bottom-right-radius: 0"
              @click="refreshData()"
            >
              <vue-feather type="refresh-ccw"></vue-feather>
            </button>
            <button
              type="button"
              class="btn btn-dark btn-icon btn--group"
              @click="refreshTodayData()"
            >
              แสดงวันนี้
            </button>
          </div>
        </div>
      </div>

      <div class="row row-xs mg-md-t-10">
        <!-- col -->
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 mg-t-10 mg-lg-t-0">
          <div class="row row-xs">
            <!-- col -->
            <div
              class="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-10 mg-lg-t-0"
            >
              <div class="card">
                <div class="card-body">
                  <ul class="nav nav-line" role="">
                    <li class="nav-item">
                      <a
                        class="nav-link cursor-pointer"
                        :class="currentTab === '00' ? 'active  tx-bold' : ''"
                        @click="(currentTab = '00'), refreshData()"
                        >ทั้งหมด&nbsp;&nbsp;<span class="badge badge-primary">{{
                          nStep[0]
                        }}</span></a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link cursor-pointer"
                        :class="currentTab === '01' ? 'active  tx-bold' : ''"
                        @click="(currentTab = '01'), refreshData()"
                        >ออกเอกสาร &nbsp;&nbsp;<span
                          class="badge badge-light"
                          >{{ nStep[1] }}</span
                        ></a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link cursor-pointer"
                        :class="currentTab === '02' ? 'active  tx-bold' : ''"
                        @click="(currentTab = '02'), refreshData()"
                        >จัดเตรียมยาง &nbsp;&nbsp;<span
                          class="badge badge-warning"
                          >{{ nStep[2] }}</span
                        ></a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link cursor-pointer"
                        :class="currentTab === '03' ? 'active  tx-bold' : ''"
                        @click="(currentTab = '03'), refreshData()"
                        >พร้อมโหลด &nbsp;&nbsp;<span
                          class="badge badge-success"
                          >{{ nStep[3] }}</span
                        ></a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link cursor-pointer"
                        :class="currentTab === '04' ? 'active  tx-bold' : ''"
                        @click="(currentTab = '04'), refreshData()"
                        >จบงาน &nbsp;&nbsp;<span class="badge badge-dark">{{
                          nStep[4]
                        }}</span></a
                      >
                    </li>
                  </ul>
                  <div class="table-responsive mg-t-20">
                    <table class="table table-sm table-hover dt-table">
                      <thead>
                        <tr class="nowrap">
                          <td>ลำดับ</td>

                          <td>เลขที่เอกสาร</td>
                          <td>อินวอยซ์</td>

                          <td>สถานะ</td>
                          <td>ลูกค้า</td>
                          <td>จำนวนยาง</td>

                          <td>จำนวนลาเบล</td>
                          <td>อายุ</td>
                          <td>วันที่อนุมัติ</td>
                          <td>ดำเนินการ</td>
                        </tr>
                      </thead>
                      <tbody class="tx-roboto">
                        <tr
                          v-for="(b, index) in dTable"
                          :key="index"
                          class="nowrap cursor-pointer"
                          @click="popup(b.GCOS_No)"
                        >
                          <td>{{ index + 1 }}</td>
                          <td>{{ b.GCOS_No }}</td>
                          <td>
                            {{
                              b.Invoice_No.substr(0, 5) === "00150"
                                ? b.Invoice_No.substr(2, 8)
                                : b.Invoice_No.substr(0, 4) !== "00TR"
                                ? b.Invoice_No.substr(2, 6) +
                                  "-" +
                                  b.Invoice_No.substr(8, 2)
                                : b.Invoice_No.substr(2, 8)
                            }}
                          </td>

                          <td
                            class="tx-thai"
                            :class="[
                              b.GCOS_Status === '01' ? '' : '',
                              b.GCOS_Status === '02' ? 'bg-warning' : '',
                              b.GCOS_Status === '03'
                                ? 'bg-success tx-white'
                                : '',
                              b.GCOS_Status === '04' ? 'bg-dark tx-white' : '',
                            ]"
                          >
                            {{
                              b.GCOS_Status === "01"
                                ? "ออกเอกสาร"
                                : b.GCOS_Status === "02"
                                ? "จัดเตรียมยาง"
                                : b.GCOS_Status === "03"
                                ? "พร้อมโหลด"
                                : b.GCOS_Status === "04"
                                ? "จบงาน"
                                : ""
                            }}
                          </td>
                          <td>{{ b.Delivery_Place_Line_1 }}</td>
                          <td align="right">{{ b.Total_Order_Qty }}</td>

                          <td>{{ b.Label_Qty }}</td>
                          <td class="tx-pink">
                            {{
                              b.LockWeek ? b.LockWeek + "/" + b.LockYear : ""
                            }}
                          </td>
                          <td class="tx-black">
                            {{ b.Approve_Date }}
                          </td>
                          <td>
                            <button
                              class="btn btn-xs btn-icon btn-dark mg-r-2 pd-y-0 pd-x-1"
                              @click="popup(b.GCOS_No)"
                            >
                              <vue-feather
                                type="edit-3"
                                class="mg-t-3"
                              ></vue-feather>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- col -->
            <!-- col -->
          </div>
          <!-- row -->
        </div>

        <!-- col -->
      </div>
      <!-- row -->
    </div>
  </div>
  <div
    id="mailCompose"
    class="mail-compose"
    :class="[popupOpen ? ' show ' : ' d-none', popupShrink ? ' shrink ' : '']"
    v-if="popupOpen"
  >
    <div class="mail-compose-dialog">
      <div class="mail-compose-header">
        <h4 class="mail-compose-title tx-white fw-bold mg-l-10 tx-15">
          {{ popupCode }}
        </h4>
        <nav class="nav nav-icon-only">
          <a
            id="mailComposeClose"
            href="#"
            @click="(popupOpen = false), (popupShrink = false)"
            class="nav-link nav-link-close"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line></svg
          ></a>
        </nav>
      </div>
      <!-- mail-compose-header -->
      <div class="mail-compose-body h100vh">
        <div class="row no-gutters rounded">
          <!-- col -->
          <div class="col-12 bg-white">
            <div class="ht-100p justify-content-centerx">
              <table class="table table-sm">
                <thead>
                  <tr>
                    <td rowspan="2">ลำดับ</td>
                    <td rowspan="2">ไซส์ยาง</td>
                    <td rowspan="2">ประเภทสต๊อก</td>
                    <td rowspan="2">แอดเดรส</td>

                    <td colspan="3" class="tx-bolder" align="center">จำนวน</td>
                    <td
                      colspan="2"
                      rowspan="2"
                      class="tx-bolder"
                      align="center"
                    >
                      ข้อกำหนด
                    </td>
                    <td colspan="2" class="tx-bolder" align="center">
                      กำหนดอายุ
                    </td>
                  </tr>
                  <tr>
                    <td align="center">ยาง</td>
                    <td align="center">ลาเบล</td>
                    <td align="center">ส่วนต่าง</td>
                    <td align="center">สัปดาห์</td>
                    <td align="center">ปี</td>
                  </tr>
                </thead>
                <tbody class="tx-roboto">
                  <tr
                    v-for="(b, index) in popupData"
                    :key="index"
                    class="nowrap"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>
                      {{ b.GT_Code + b.CT_Flag + b.KK_Flag }}
                    </td>
                    <td>{{ b.StockType }}</td>
                    <td>{{ b.Address_Code }}</td>
                    <td align="right">{{ b.Order_Qty }}</td>
                    <td>
                      <input
                        type="tel"
                        class="form-control input_w"
                        v-model="b.Label_Qty"
                      />
                    </td>
                    <td
                      align="right"
                      :class="b.Order_Qty - b.Label_Qty < 0 ? 'tx-danger' : ''"
                    >
                      {{ b.Order_Qty - b.Label_Qty }}
                    </td>
                    <td class="tx-bold tx-pink">{{ b.Req1 }}</td>
                    <td class="tx-bold tx-pink">{{ b.Req2 }}</td>
                    <td>
                      <input
                        type="tel"
                        class="form-control input-sm input_w"
                        v-model="b.PWEEK"
                      />
                    </td>
                    <td>
                      <input
                        type="tel"
                        class="form-control input-sm input_w"
                        v-model="b.PYEAR"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div>
                <button
                  id="btn-approve"
                  class="th btn btn-success btn-uppercase mg-r-4"
                  @click="doSave"
                >
                  บันทึกข้อมูล
                </button>
                <button
                  @click="popupOpen = false"
                  class="th btn btn-dark btn-uppercase mg-r-4"
                >
                  ปิดหน้าต่าง
                </button>
              </div>
            </div>
          </div>
          <!-- col -->
        </div>
      </div>
      <!-- mail-compose-body -->
    </div>
    <!-- mail-compose-dialog -->
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { useToast } from "vue-toastification";
import $ from "jquery";
import axios from "axios";
import configs from "@/config/config";
import feather from "feather-icons";
import moment from "moment";
const icons = Object.keys(feather.icons);
export default {
  data() {
    return {
      icons,
      isLoading: false,
      fullPage: true,
      dataLastUpdate: "",
      currentTab: "00",
      nStep: [0, 0, 0, 0, 0],
      dTable: null,
      popupOpen: false,
      popupShrink: false,
      popupData: null,
      popupCode: "",
    };
  },
  service: null,
  components: {
    Loading,
  },
  setup() {
    // Get toast interface
    const toast = useToast();
    // Make it available inside methods
    return { toast };
  },
  computed: {
    currentUser() {
      return this.$store.state.authen.user;
    },
    loggedIn() {
      return this.$store.state.authen.status.loggedIn;
    },
    currentPath() {
      return this.$route.path;
    },
    prefix() {
      return this.$store.state.prefix;
    },
  },
  created() {
    if (
      this.prefix === "" ||
      this.prefix === null ||
      this.prefix === undefined
    ) {
      window.location.replace("/");
    }
    this.inputDateStart = moment().format("YYYY-MM-DD");
    this.inputDateEnd = moment().format("YYYY-MM-DD");
  },
  async mounted() {
    await this.refreshData();

    setTimeout(() => {
      this.refreshData();
    }, 300000);
  },
  methods: {
    refreshTodayData() {
      this.inputDateStart = moment().format("YYYY-MM-DD");
      this.inputDateEnd = moment().format("YYYY-MM-DD");
      this.refreshData();
    },
    async refreshData() {
      this.dTable = [];
      this.nStep = [0, 0, 0, 0, 0];
      if ($.fn.DataTable.isDataTable(".dt-table")) {
        $(".dt-table").DataTable().destroy();
      }
      this.isLoading = true;

      await axios
        .get(configs.urlApi + "/" + this.prefix.toLowerCase() + "/outbound", {
          params: {
            action: "get-outbound-list",
            dStart: this.inputDateStart,
            dEnd: this.inputDateEnd,
            dFilter: this.currentTab,
          },
        })
        .then((response) => {
          console.log(response.data);
          let data = response.data;
          this.nStep = data.counter;
          this.dTable = data.data;
          this.dataLastUpdate = data.current;
          this.isLoading = false;
        });
      $(".dt-table").DataTable({
        iDisplayLength: -1,
        bAutoWidth: false,
        bLengthChange: true,
        lengthMenu: [
          [10, 15, 20, 30, -1],
          [10, 15, 20, 30, "แสดงทั้งหมด"],
        ],
        oLanguage: {
          searchPlaceholder: "ค้นหา...",
          sInfo: "แสดงรายการที่ _START_ ถึง _END_ (จาก  _TOTAL_ รายการ)",
          sSearch: "",
          sInfoEmpty: "&nbsp;",
          sInfoFiltered: " ค้นหาจาก  _MAX_ รายการ",
          sZeroRecords: "ไม่มีข้อมูล",
          lengthMenu: "_MENU_",
        },
        language: {
          paginate: {
            previous: "&lt;",
            next: "&gt;",
          },
        },
      });
      $(".dataTables_length select").addClass("form-control");
      // $(".dt-table").DataTable().reload();
      setTimeout(() => {
        this.isLoading = false;
      }, 300);
    },
    async popup(gcos) {
      this.popupData = [];
      this.popupCode = gcos;
      this.popupOpen = true;
      this.isLoading = true;
      await axios
        .get(configs.urlApi + "/" + this.prefix.toLowerCase() + "/outbound", {
          params: {
            action: "get-outbound-detail",
            gcos: gcos,
          },
        })
        .then((response) => {
          console.log(response.data);
          let data = response.data;
          this.popupData = data.data;
          this.isLoading = false;
        });
      console.log(gcos);
      setTimeout(() => {
        this.isLoading = false;
      }, 300);
    },
    async doSave() {
      let isErrorAging = false;
      let isErrorLabel = false;
      this.popupData.map((item) => {
        console.log(item);
        if (!item.PWEEK || item.PWEEK === "" || item.PWEEK === null) {
          isErrorAging = true;
        }
        if (item.Label_Qty > item.Order_Qty) {
          isErrorLabel = true;
        }
      });
      if (isErrorAging) {
        this.toast.error("กรุณากำหนดอายุของยางให้ครบถ้วน", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: false,
          rtl: false,
        });
      }
      if (isErrorLabel) {
        this.toast.error("คุณระบุจำนวนลาเบลมากกว่าจำนวนยาง", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: false,
          rtl: false,
        });
      }
      if (!isErrorAging && !isErrorLabel) {
        let strSubmit = "";
        this.popupData.map((item) => {
          strSubmit =
            strSubmit +
            "|" +
            item.GT_Code +
            ":" +
            item.CT_Flag +
            ":" +
            item.KK_Flag +
            ":" +
            item.Address_Code +
            ":" +
            item.Label_Qty +
            ":" +
            item.PWEEK +
            ":" +
            item.PYEAR;
        });
        let formData = new FormData();
        formData.append("submit_data", strSubmit);
        formData.append("gcos", this.popupCode);
        formData.append("action", "update-outbound-condition");
        axios
          .post(
            configs.urlApi + "/" + this.prefix.toLowerCase() + "/outbound",
            formData
          )
          .then((res) => {
            console.log(res.data);
            this.toast.success("บันทึกข้อมูลสำเร็จ", {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: false,
              rtl: false,
            });
            this.popupCode = "";
            this.popupDate = [];
            this.popupOpen = false;
            this.refreshData();
          });
      }
    },
  },
};
</script>
<style scoped>
.input_w {
  max-width: 80px;
  text-align: right;
  height: 22px;
}
.table-sm th,
.table-sm td {
  padding: 1px 10px;
}
</style>
