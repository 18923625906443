<template>
  <div>
    <loading
      v-model:active="isLoading"
      :is-full-page="fullPage"
      color="#007070"
      opacity="0.5"
    ></loading>

    <div>
      <div class="d-lg-flex align-items-center justify-content-between mg-b-10">
        <h5 class="mg-b-10">
          คอนเฟิร์มตู้ของวันที่
          <b class="tx-primary">{{ selectDateStart }}</b> ถึง
          <b class="tx-primary">{{ selectDateEnd }}</b>
        </h5>
        <div class="d-block">
          <div class="input-group">
            <select
              v-if="userInfo?.company === 'BTMT'"
              v-model="inputTransport"
              class="form-control"
              @change="refreshData"
            >
              <option value="%">แสดงทั้งหมด</option>
              <option
                v-for="(t, index) in transportCompany"
                :key="index"
                :value="t.C_CODE"
              >
                {{ t.C_CODE }}
              </option>
            </select>
            <input type="date" class="form-control" v-model="inputDateStart" />
            <input type="date" class="form-control" v-model="inputDateEnd" />
            <button
              type="button"
              class="btn btn-primary btn-icon btn--group"
              style="border-top-right-radius: 0; border-bottom-right-radius: 0"
              @click="refreshData()"
            >
              <vue-feather type="search"></vue-feather>
            </button>
            <button
              type="button"
              class="btn btn-success btn-icon btn--group"
              style="border-top-right-radius: 0; border-bottom-right-radius: 0"
              @click="refreshData()"
            >
              <vue-feather type="refresh-ccw"></vue-feather>
            </button>
            <button
              type="button"
              class="btn btn-dark btn-icon btn--group"
              @click="refreshTodayData()"
            >
              แสดงวันนี้
            </button>
          </div>
        </div>
      </div>
      <div class="row row-xs">
        <!-- col -->
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 mg-t-10 mg-lg-t-0">
          <div class="row row-xs">
            <!-- col -->
            <div
              class="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-10 mg-lg-t-0"
            >
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive">
                    <table
                      class="table table-sm table-hover dt-table tx-roboto"
                    >
                      <thead>
                        <tr class="nowrap tx-thai">
                          <td>วันที่โหลด</td>
                          <td>เลขที่เอกสาร</td>
                          <td>อินวอย</td>
                          <td>ประเทศ</td>

                          <td>บริษัท</td>
                          <td>ขนาดตู้</td>
                          <td>จำนวน</td>
                          <td>วันที่อนุมัติ</td>
                          <!-- <td>สถานะตู้</td> -->
                          <td>ดำเนินการ</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(iv, index2) in dataInvoice"
                          :key="index2"
                          class="nowrap"
                          :class="[iv.TDP_IS_DELAY === '1' ? 'tx-pink' : '']"
                        >
                          <td>
                            {{ iv.TDP_LOADING_DATE }} (P{{
                              iv.TDP_LOADING_PERIOD
                            }})
                          </td>

                          <td>{{ iv.TGL_GCOS_NO }}</td>
                          <td>{{ iv.EPH_INVOICE_NO }}-{{ iv.EPH_SEQ_NO }}</td>

                          <td>{{ iv.EIM_DESTINATION }}</td>
                          <td>{{ iv.TDP_LOADING_EXPRESS }}</td>
                          <td>{{ iv.EPH_CONTAINER_TYPE }}</td>
                          <td>{{ iv.EPH_QTY_TOTAL }}</td>
                          <td>{{ iv.TGL_APPROVE_DATE }}</td>

                          <!-- <td>
                            <div class="btn-group">
                              <button
                                class="btn btn-sm btn-icon mg-r-2"
                                :class="[
                                  iv.TGP_CONTAINER_OKNG === 'OK'
                                    ? ' btn-success'
                                    : ' btn-dark',
                                ]"
                              >
                                OK
                              </button>
                              <button
                                class="btn btn-sm btn-icon"
                                :class="[
                                  iv.TGP_CONTAINER_OKNG === 'NG'
                                    ? ' btn-danger'
                                    : ' btn-dark',
                                ]"
                              >
                                NG
                              </button>
                            </div>
                          </td> -->
                          <td>
                            <button
                              class="btn btn-xs btn-icon btn-dark mg-r-2 pd-y-0 pd-x-1 pd-t-4"
                              @click="periodCommentPopup(iv)"
                              v-tooltip="'คอมเมนต์'"
                            >
                              <vue-feather type="more-horizontal"></vue-feather>
                            </button>
                            <!-- <button
                              class="btn btn-sm btn-icon btn-primary mg-r-2"
                              @click="periodSetupPopup(iv)"
                              v-tooltip="'ขอเปลี่ยนแผน'"
                            >
                              <vue-feather type="edit"></vue-feather>
                            </button> -->
                            <button
                              class="btn btn-xs btn-icon btn-dark mg-r-2 pd-y-0 pd-x-1 pd-t-4"
                              v-if="iv.TTC_CONFIRM !== 'Y'"
                              @click="transportConfirm(iv, 'Y')"
                              v-tooltip="'คอนเฟิร์ม'"
                            >
                              <vue-feather type="circle"></vue-feather>
                            </button>
                            <button
                              class="btn btn-xs btn-icon btn-success mg-r-2 pd-y-0 pd-x-1 pd-t-4"
                              v-tooltip="'ยกเลิกคอนเฟิร์ม'"
                              v-if="
                                iv.TTC_CONFIRM === 'Y' &&
                                (!iv.TGP_CONTAINER_OKNG ||
                                  iv.TGP_CONTAINER_OKNG === '')
                              "
                              @click="transportConfirm(iv, 'N')"
                            >
                              <vue-feather type="check-circle"></vue-feather>
                            </button>
                            <!-- <button
                              class="btn btn-sm btn-icon btn-danger mg-r-2"
                              v-if="iv.TGP_CONTAINER_OKNG === 'NG'"
                              @click="transportConfirm(iv, 'R')"
                              v-tooltip="'ส่งตู้ใหม่'"
                            >
                              <vue-feather type="circle"></vue-feather>
                            </button> -->
                            <button
                              class="btn btn-xs btn-icon btn-success mg-r-2 pd-y-0 pd-x-1 pd-t-4"
                              v-if="
                                iv.TTC_CONFIRM === 'Y' &&
                                iv.TGP_CONTAINER_OKNG === 'OK'
                              "
                            >
                              <vue-feather type="check-circle"></vue-feather>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- col -->
            <!-- col -->
          </div>
          <!-- row -->
        </div>
        <!-- col -->
      </div>
      <!-- row -->
    </div>
  </div>

  <div
    id="mailCompose"
    class="mail-compose"
    :class="[popupOpen ? ' show ' : ' d-none', popupShrink ? ' shrink ' : '']"
  >
    <div class="mail-compose-dialog">
      <div class="mail-compose-header">
        <h4 class="mail-compose-title fw-bold mg-l-10">
          {{ popupInvoiceNo }}
        </h4>
        <nav class="nav nav-icon-only">
          <a
            v-if="!popupShrink"
            id="mailComposeShrink"
            href="#"
            @click="popupShrink = true"
            class="nav-link nav-link-shrink d-none d-lg-block"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-minimize-2"
            >
              <polyline points="4 14 10 14 10 20"></polyline>
              <polyline points="20 10 14 10 14 4"></polyline>
              <line x1="14" y1="10" x2="21" y2="3"></line>
              <line x1="3" y1="21" x2="10" y2="14"></line>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-maximize-2"
            >
              <polyline points="15 3 21 3 21 9"></polyline>
              <polyline points="9 21 3 21 3 15"></polyline>
              <line x1="21" y1="3" x2="14" y2="10"></line>
              <line x1="3" y1="21" x2="10" y2="14"></line>
            </svg>
          </a>
          <a
            v-if="popupShrink"
            id="mailComposeShrink"
            href="#"
            @click="popupShrink = false"
            class="nav-link nav-link-shrink d-none d-lg-block"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-minimize-2"
            >
              <polyline points="4 14 10 14 10 20"></polyline>
              <polyline points="20 10 14 10 14 4"></polyline>
              <line x1="14" y1="10" x2="21" y2="3"></line>
              <line x1="3" y1="21" x2="10" y2="14"></line>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-maximize-2"
            >
              <polyline points="15 3 21 3 21 9"></polyline>
              <polyline points="9 21 3 21 3 15"></polyline>
              <line x1="21" y1="3" x2="14" y2="10"></line>
              <line x1="3" y1="21" x2="10" y2="14"></line>
            </svg>
          </a>
          <a
            id="mailComposeClose"
            href="#"
            @click="(popupOpen = false), (popupShrink = false)"
            class="nav-link nav-link-close"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line></svg
          ></a>
        </nav>
      </div>
      <!-- mail-compose-header -->
      <div class="mail-compose-body h100vh">
        <div class="table-responsive">
          <table class="table table-sm">
            <thead>
              <tr class="nowrap">
                <td>ตู้ที่</td>

                <td>จำนวน</td>
                <td>วันที่โหลด</td>
                <td>ยืนยันวันที่โหลด</td>
                <td>PERIOD</td>

                <td>สาเหตุ</td>
                <td></td>
                <td>บันทึกล่าสุด</td>
              </tr>
            </thead>
            <tbody>
              <tr
                class="nowrap"
                v-for="(iv, index) in popupDataInvoice"
                :key="index"
                :class="[
                  this.popupInvoiceNo == iv.EPH_INVOICE_NO &&
                  this.popupSeqNo == iv.EPH_SEQ_NO
                    ? 'row-hilight'
                    : '',
                ]"
              >
                <td>{{ iv.EPH_SEQ_NO }}</td>

                <td>{{ iv.EPH_QTY_TOTAL }}</td>
                <td>{{ iv.EPH_LOADING_DATE }}</td>
                <td>
                  <input
                    type="date"
                    class="form-control"
                    v-model="iv.TDP_LOADING_DATE"
                  />
                </td>
                <td>
                  <select v-model="iv.TDP_LOADING_PERIOD" class="form-control">
                    <option value=""></option>
                    <option v-for="i in 6" :value="i" :key="i">P{{ i }}</option>
                  </select>
                </td>

                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="iv.TDP_TRANSPORT_CHANGE_REASON"
                  />
                </td>
                <td>
                  <button
                    class="btn btn-sm btn-icon btn-dark mg-r-2"
                    @click="periodCommentPopup(iv)"
                    v-tooltip="'คอมเมนต์'"
                  >
                    <vue-feather type="message-square"></vue-feather>
                  </button>
                  <button
                    class="btn btn-sm btn-icon btn-primary mg-r-2"
                    @click="periodRequest(iv)"
                    v-tooltip="'ส่งคำขอ'"
                  >
                    <vue-feather type="send"></vue-feather>
                  </button>
                </td>
                <td>{{ iv.TDP_UPDATE_DATE }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- mail-compose-body -->
    </div>
    <!-- mail-compose-dialog -->
  </div>

  <div
    id="mailCompose2"
    class="mail-compose"
    :class="[
      popupCommentOpen ? ' show ' : ' d-none',
      popupCommentShrink ? ' shrink ' : '',
    ]"
    v-if="this.popupCommentDataInvoice !== null"
  >
    <div class="mail-compose-dialog">
      <div class="mail-compose-header">
        <h4 class="mail-compose-title fw-bold mg-l-10 text-white">
          {{ this.popupCommentDataInvoice.EPH_INVOICE_NO }}-{{
            this.popupCommentDataInvoice.EPH_SEQ_NO
          }}
        </h4>
        <nav class="nav nav-icon-only">
          <a
            v-if="!popupCommentShrink"
            id="mailComposeShrink"
            href="#"
            @click="popupCommentShrink = true"
            class="nav-link nav-link-shrink d-none d-lg-block"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-minimize-2"
            >
              <polyline points="4 14 10 14 10 20"></polyline>
              <polyline points="20 10 14 10 14 4"></polyline>
              <line x1="14" y1="10" x2="21" y2="3"></line>
              <line x1="3" y1="21" x2="10" y2="14"></line>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-maximize-2"
            >
              <polyline points="15 3 21 3 21 9"></polyline>
              <polyline points="9 21 3 21 3 15"></polyline>
              <line x1="21" y1="3" x2="14" y2="10"></line>
              <line x1="3" y1="21" x2="10" y2="14"></line>
            </svg>
          </a>
          <a
            v-if="popupCommentShrink"
            id="mailComposeShrink"
            href="#"
            @click="popupCommentShrink = false"
            class="nav-link nav-link-shrink d-none d-lg-block"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-minimize-2"
            >
              <polyline points="4 14 10 14 10 20"></polyline>
              <polyline points="20 10 14 10 14 4"></polyline>
              <line x1="14" y1="10" x2="21" y2="3"></line>
              <line x1="3" y1="21" x2="10" y2="14"></line>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-maximize-2"
            >
              <polyline points="15 3 21 3 21 9"></polyline>
              <polyline points="9 21 3 21 3 15"></polyline>
              <line x1="21" y1="3" x2="14" y2="10"></line>
              <line x1="3" y1="21" x2="10" y2="14"></line>
            </svg>
          </a>
          <a
            id="mailComposeClose"
            href="#"
            @click="(popupCommentOpen = false), (popupCommentShrink = false)"
            class="nav-link nav-link-close"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line></svg
          ></a>
        </nav>
      </div>
      <!-- mail-compose-header -->
      <div class="mail-compose-body h100vh">
        <div class="timeline-group tx-13">
          <div class="timeline-label">ประวัติรายการ</div>
          <div
            class="timeline-item"
            v-for="(c, index) in popupCommentDataComment"
            :key="index"
          >
            <div class="timeline-time">
              {{ c.TCM_DATETIME.substr(0, 10) }}<br />{{
                c.TCM_DATETIME.substr(11, 8)
              }}
            </div>
            <div class="timeline-body">
              <span class="">{{ c.TCM_COMPANY }}</span
              ><br />

              <p class="">
                {{ c.TCM_COMMENT }}
              </p>
            </div>
            <!-- timeline-body -->
          </div>
        </div>

        <hr class="mg-y-10" />
        <div class="form-row align-items-center">
          <div class="col-sm mg-b-10">คอมเมนต์เพิ่มเติม:</div>
        </div>
        <textarea
          class="form-control"
          rows="5"
          placeholder="ระบุรายละเอียด"
          v-model="popupCommentText"
        ></textarea>

        <div
          class="d-sm-flex align-items-center justify-content-between mg-t-25"
        >
          <div></div>
          <div class="tx-13 mg-t-15 mg-sm-t-0">
            <button class="btn btn-primary" @click="doSaveComment()">
              บันทึก
            </button>
          </div>
        </div>
      </div>

      <!-- mail-compose-body -->
    </div>
    <!-- mail-compose-dialog -->
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { useToast } from "vue-toastification";
import LobService from "@/services/lob";
import AuthService from "@/services/authen";
import $ from "jquery";
import moment from "moment";

import feather from "feather-icons";
const icons = Object.keys(feather.icons);

export default {
  data() {
    return {
      icons,
      isLoading: true,
      fullPage: true,
      inputTransport: null,
      inputDateStart: null,
      inputDateEnd: null,
      inputStatus: "ALL",
      selectTransport: null,
      selectDateStart: null,
      selectDateEnd: null,
      popupOpen: false,
      popupInvoiceNo: "",
      popupSeqNo: "",
      popupDataInvoice: "",

      popupCommentInvoiceNo: "",
      popupCommentSeqNo: "",
      popupCommentDataInvoice: null,
      popupCommentDataComment: null,
      popupCommentText: "",
      popupCommentOpen: false,
      popupCommentShrink: false,
      popupShrink: false,

      dataInvoice: null,
      selectTab: null,
      transportCompany: null,
      filterBox: false,

      userInfo: null,
    };
  },
  service: null,
  components: {
    Loading,
  },
  setup() {
    // Get toast interface
    const toast = useToast();

    // Make it available inside methods
    return { toast };
  },
  computed: {
    currentUser() {
      return this.$store.state.authen.user;
    },
    loggedIn() {
      return this.$store.state.authen.status.loggedIn;
    },
    currentPath() {
      return this.$route.path;
    },
  },
  created() {
    this.getUserInfo();
    this.inputDateStart = new Date().toISOString().slice(0, 10);
    this.inputDateEnd = new Date().toISOString().slice(0, 10);
    this.inputTransport = "%";

    this.selectDateStart = this.inputDateStart;
    this.selectDateEnd = this.inputDateEnd;

    this.service = new LobService();
  },
  async mounted() {
    await this.refreshData();
    this.getTransport();
  },
  methods: {
    getUserInfo() {
      this.userInfo = AuthService.getInfo();
    },
    getComment(invoiceNo, seqNo) {
      this.service.getStuffingInvoiceComment(invoiceNo, seqNo).then((rez) => {
        console.log(rez);
        this.popupCommentDataComment = rez.data.dataComment;
      });
    },
    doSaveComment() {
      if (this.popupCommentText !== "" && this.popupCommentText !== null) {
        this.service
          .addNewComment(
            this.popupCommentDataInvoice,
            this.currentUser,
            this.popupCommentText
          )
          .then((rez) => {
            if (rez.data.isSuccess === 1) {
              this.toast.success("บันทึกข้อมูลสำเร็จ", {
                position: "top-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: false,
                rtl: false,
              });
              this.getComment(
                this.popupCommentDataInvoice.EPH_INVOICE_NO,
                this.popupCommentDataInvoice.EPH_SEQ_NO
              );
              this.popupCommentText = "";
            }
          });
      } else {
        this.toast.error("กรุณาระบุข้อความที่ต้องการคอมเมนต์", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: false,
          rtl: false,
        });
      }
    },
    getTransport() {
      this.service.getStuffingTransportCompany().then((rez) => {
        this.transportCompany = rez.data.dataTransport;
      });
    },
    periodCommentPopup(iv) {
      this.popupCommentOpen = true;
      this.popupCommentOpen = true;
      this.popupCommentDataInvoice = iv;
      this.getComment(iv.EPH_INVOICE_NO, iv.EPH_SEQ_NO);
      console.log(iv);
    },
    async periodRequest(iv) {
      this.Loading = true;
      console.log(iv);
      if (
        iv.TDP_TRANSPORT_CHANGE_REASON !== "" &&
        iv.TDP_TRANSPORT_CHANGE_REASON !== null
      ) {
        await this.service
          .requestChangePeriod(
            iv,
            this.currentUser,
            iv.TDP_TRANSPORT_CHANGE_REASON
          )
          .then((rez) => {
            if (rez.data.isSuccess === 1) {
              this.toast.success(
                "#" + rez.data.reqNo + " ส่งคำขอย้ายแผนโหลดสำเร็จ",
                {
                  position: "top-right",
                  timeout: 5000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: false,
                  closeButton: "button",
                  icon: false,
                  rtl: false,
                }
              );
              iv.TDP_TRANSPORT_CHANGE_REASON = null;
              this.popupOpen = false;
            }
          });
      } else {
        this.toast.error("กรุณาระบุเหตุผลในการขอย้ายแผน", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: false,
          rtl: false,
        });
      }
      this.Loading = true;
    },
    periodSetupPopup(iv) {
      this.popupOpen = true;
      this.popupInvoiceNo = iv.EPH_INVOICE_NO;
      this.popupSeqNo = iv.EPH_SEQ_NO;
      this.refreshDataInvoice();
    },
    refreshDataInvoice() {
      this.isLoading = true;
      this.selectInvoice = this.popupInvoiceNo;
      this.service.getStuffingInvoice(this.selectInvoice).then((rez) => {
        console.log(rez.data);
        this.popupDataInvoice = rez.data.dataInvoice;
      });
      setTimeout(() => {
        this.isLoading = false;
      }, 300);
    },
    refreshTodayData() {
      this.inputDateStart = moment().format("YYYY-MM-DD");
      this.inputDateEnd = moment().format("YYYY-MM-DD");
      this.refreshData();
    },
    async refreshData() {
      this.isLoading = true;

      this.selectDateStart = this.inputDateStart;
      this.selectDateEnd = this.inputDateEnd;

      console.log("userinfo =>", this.userInfo.company);
      if (this.userInfo.company === "BTMT") {
        this.selectTransport = this.inputTransport;
      } else {
        this.selectTransport = this.userInfo.company;
      }

      if ($.fn.DataTable.isDataTable(".dt-table")) {
        $(".dt-table").DataTable().destroy();
      }

      await this.service
        .getStuffingPeriodTransport(
          this.selectDateStart,
          this.selectDateEnd,
          this.selectTransport,
          this.inputStatus
        )
        .then((rez) => {
          console.log(rez.data);
          this.dataInvoice = rez.data.dataInvoice;
        });

      $(".dt-table").DataTable({
        iDisplayLength: 20,
        bAutoWidth: false,
        bLengthChange: true,
        lengthMenu: [
          [10, 15, 20, 30, -1],
          [10, 15, 20, 30, "แสดงทั้งหมด"],
        ],
        oLanguage: {
          searchPlaceholder: "ค้นหา...",
          sInfo: "แสดงรายการที่ _START_ ถึง _END_ (จาก  _TOTAL_ รายการ)",
          sSearch: "",
          sInfoEmpty: "&nbsp;",
          sInfoFiltered: " ค้นหาจาก  _MAX_ รายการ",
          sZeroRecords: "ไม่มีข้อมูล",
          lengthMenu: "_MENU_",
        },
        language: {
          paginate: {
            previous: "&lt;",
            next: "&gt;",
          },
        },
      });
      $(".dataTables_length select").addClass("form-control");
      setTimeout(() => {
        this.isLoading = false;
      }, 300);
    },

    transportConfirm(iv, confirmFlag) {
      this.service
        .transportConfirm(iv, this.currentUser, confirmFlag)
        .then((response) => {
          console.log(response.data);
          this.refreshData();

          this.toast.success(
            "(" + iv.EPH_INVOICE_NO + "-" + iv.EPH_SEQ_NO + ") บันทึกสำเร็จ",
            {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: false,
              rtl: false,
            }
          );
        });
      //
    },
  },
};
</script>
<style scoped>
.input_w {
  max-width: 80px;
  text-align: right;
  height: 22px;
}
.table-sm th,
.table-sm td {
  padding: 1px 10px;
}
</style>
