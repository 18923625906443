import axios from "axios";
import configs from "../config/config";

class AuthService {
  login(user) {
    let formData = new FormData();
    formData.append("action", "trylogin");
    formData.append("user", user.username);
    formData.append("password", user.password);

    return axios
      .post(configs.urlApi + "/auth/login", formData)
      .then((response) => {
        let data = response.data;
        console.log(data);
        if (data.result === 1) {
          localStorage.removeItem("prefix");
          localStorage.setItem("user", user.username);
          localStorage.setItem("userInfo", JSON.stringify(data.user_info));
        }
        return data;
      });
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("prefix");
  }

  getInfo() {
    return JSON.parse(localStorage.getItem("userInfo"));
  }
  async getMenu(prefix, user) {
    return axios
      .get(configs.urlApi + "/master/get-permissions", {
        params: {
          prefix,
          user: user,
        },
      })
      .then((response) => {
        let data = response.data;

        return data;
      });
  }
}
export default new AuthService();
