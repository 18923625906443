<template>
  <div>
    <loading
      v-model:active="isLoading"
      :is-full-page="fullPage"
      color="#007070"
      opacity="0.5"
    ></loading>

    <div>
      <center></center>
      <div class="d-lg-flex align-items-center justify-content-between mg-b-10">
        <h5 class="mg-b-10">
          สถานะการขนส่งของวันที่
          <b class="tx-primary">{{ selectDateStart }}</b> ถึง
          <b class="tx-primary">{{ selectDateEnd }}</b>
        </h5>
        <div class="d-block">
          <div class="input-group">
            <select
              v-if="userInfo?.company === 'BTMT'"
              v-model="inputTransport"
              class="form-control"
              @change="refreshData"
            >
              <option value="%">แสดงทั้งหมด</option>
              <option
                v-for="(t, index) in transportCompany"
                :key="index"
                :value="t.C_CODE"
              >
                {{ t.C_CODE }}
              </option>
            </select>
            <input type="date" class="form-control" v-model="inputDateStart" />
            <input type="date" class="form-control" v-model="inputDateEnd" />
            <button
              type="button"
              class="btn btn-primary btn-icon btn--group"
              style="border-top-right-radius: 0; border-bottom-right-radius: 0"
              @click="refreshData()"
            >
              <vue-feather type="search"></vue-feather>
            </button>
            <button
              type="button"
              class="btn btn-success btn-icon btn--group"
              style="border-top-right-radius: 0; border-bottom-right-radius: 0"
              @click="refreshData()"
            >
              <vue-feather type="refresh-ccw"></vue-feather>
            </button>
            <button
              type="button"
              class="btn btn-dark btn-icon btn--group"
              @click="refreshTodayData()"
            >
              แสดงวันนี้
            </button>
          </div>
        </div>
      </div>
      <div class="row row-xs">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 mg-t-10 mg-lg-t-0">
          <div class="row row-xs">
            <!-- col -->

            <div
              class="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-10 mg-lg-t-0"
            >
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive">
                    <table
                      class="table table-sm table-hover dt-table tx-roboto"
                    >
                      <thead>
                        <tr class="nowrap tx-thai">
                          <td>วันที่อนุมัติตู้</td>

                          <td>อินวอย</td>
                          <td>เลขที่เอกสาร</td>
                          <td>ประเทศ</td>

                          <td>บริษัท</td>
                          <td>สถานะ</td>
                          <td>หมายเลขตู้</td>
                          <td>หมายเลขซีล</td>

                          <td align="center"><b>(1).</b> ตู้ออก</td>
                          <td align="center"><b>(2).</b> คืนตู้</td>
                          <td align="center"><b>(3).</b> เรือออก</td>

                          <!-- <td>สถานะตู้</td> -->
                          <td>ดำเนินการ</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(iv, index2) in dataInvoice"
                          :key="index2"
                          class="nowrap"
                          :class="[iv.TDP_IS_DELAY === '1' ? 'tx-pink' : '']"
                        >
                          <td>{{ iv.TGL_APPROVE_DATE }}</td>

                          <td>{{ iv.EPH_INVOICE_NO }}-{{ iv.EPH_SEQ_NO }}</td>
                          <td>{{ iv.TGL_GCOS_NO }}</td>
                          <td>{{ iv.EIM_DESTINATION }}</td>
                          <td>{{ iv.TDP_LOADING_EXPRESS }}</td>
                          <td>
                            <div class="progress">
                              <div
                                class="progress-bar progress-bar-striped"
                                :class="
                                  iv.TGL_APPROVE_DATE !== '' &&
                                  iv.TTG_GIT_WH_DEPART === null
                                    ? 'bg-info wd-25p'
                                    : iv.TGL_APPROVE_DATE !== '' &&
                                      iv.TTG_GIT_WH_DEPART !== null &&
                                      iv.TTG_GIT_RETURN_LADEN === null
                                    ? 'bg-info wd-50p'
                                    : iv.TGL_APPROVE_DATE !== '' &&
                                      iv.TTG_GIT_RETURN_LADEN !== null &&
                                      iv.TTG_GIT_ETD_ACTUAL === null
                                    ? 'bg-info wd-75p'
                                    : iv.TGL_APPROVE_DATE !== '' &&
                                      iv.TTG_GIT_ETD_ACTUAL !== null
                                    ? 'bg-success wd-100p'
                                    : ''
                                "
                                role="progressbar"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </td>
                          <td>{{ iv.TTG_CONTAINER_NO }}</td>
                          <td>{{ iv.TTG_SEAL_NO }}</td>
                          <td>{{ iv.TTG_GIT_WH_DEPART }}</td>
                          <td>{{ iv.TTG_GIT_RETURN_LADEN }}</td>
                          <td>{{ iv.TTG_GIT_ETD_ACTUAL }}</td>

                          <!-- <td>
                            <div class="btn-group">
                              <button
                                class="btn btn-sm btn-icon mg-r-2"
                                :class="[
                                  iv.TGP_CONTAINER_OKNG === 'OK'
                                    ? ' btn-success'
                                    : ' btn-dark',
                                ]"
                              >
                                OK
                              </button>
                              <button
                                class="btn btn-sm btn-icon"
                                :class="[
                                  iv.TGP_CONTAINER_OKNG === 'NG'
                                    ? ' btn-danger'
                                    : ' btn-dark',
                                ]"
                              >
                                NG
                              </button>
                            </div>
                          </td> -->
                          <td>
                            <button
                              class="btn btn-xs btn-icon btn-dark mg-r-2 pd-y-0 pd-x-1 pd-t-4"
                              @click="periodCommentPopup(iv)"
                              v-tooltip="'คอมเมนต์'"
                            >
                              <vue-feather type="more-horizontal"></vue-feather>
                            </button>

                            <button
                              class="btn btn-xs btn-icon btn-info mg-r-2 pd-y-0 pd-x-1 pd-t-4"
                              v-tooltip="'อัพเดทสถานะ'"
                              @click="periodPopup(iv)"
                              v-if="iv.TGL_APPROVE_DATE !== ''"
                            >
                              <vue-feather type="edit-3"></vue-feather>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- col -->
            <!-- col -->
          </div>
          <!-- row -->
        </div>
        <!-- col -->
      </div>
      <!-- row -->
    </div>
  </div>

  <div
    id="mailCompose"
    class="mail-compose"
    :class="[popupOpen ? ' show ' : ' d-none', popupShrink ? ' shrink ' : '']"
  >
    <div class="mail-compose-dialog">
      <div class="mail-compose-header">
        <h4 class="mail-compose-title fw-bold mg-l-10 tx-white">
          {{ popupInvoiceNo }}-{{ popupSeqNo }}
        </h4>
        <nav class="nav nav-icon-only">
          <a
            v-if="!popupShrink"
            id="mailComposeShrink"
            href="#"
            @click="popupShrink = true"
            class="nav-link nav-link-shrink d-none d-lg-block"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-minimize-2"
            >
              <polyline points="4 14 10 14 10 20"></polyline>
              <polyline points="20 10 14 10 14 4"></polyline>
              <line x1="14" y1="10" x2="21" y2="3"></line>
              <line x1="3" y1="21" x2="10" y2="14"></line>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-maximize-2"
            >
              <polyline points="15 3 21 3 21 9"></polyline>
              <polyline points="9 21 3 21 3 15"></polyline>
              <line x1="21" y1="3" x2="14" y2="10"></line>
              <line x1="3" y1="21" x2="10" y2="14"></line>
            </svg>
          </a>
          <a
            v-if="popupShrink"
            id="mailComposeShrink"
            href="#"
            @click="popupShrink = false"
            class="nav-link nav-link-shrink d-none d-lg-block"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-minimize-2"
            >
              <polyline points="4 14 10 14 10 20"></polyline>
              <polyline points="20 10 14 10 14 4"></polyline>
              <line x1="14" y1="10" x2="21" y2="3"></line>
              <line x1="3" y1="21" x2="10" y2="14"></line>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-maximize-2"
            >
              <polyline points="15 3 21 3 21 9"></polyline>
              <polyline points="9 21 3 21 3 15"></polyline>
              <line x1="21" y1="3" x2="14" y2="10"></line>
              <line x1="3" y1="21" x2="10" y2="14"></line>
            </svg>
          </a>
          <a
            id="mailComposeClose"
            href="#"
            @click="periodPopupClose(), (popupShrink = false)"
            class="nav-link nav-link-close"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line></svg
          ></a>
        </nav>
      </div>
      <!-- mail-compose-header -->
      <div class="mail-compose-body h100vh">
        <!-- <center>
          <img
            src="../../../../assets/images/3pl-git-diagram.png"
            style="width: 80%"
            class="mx-auto pd-y-10"
          />
        </center> -->
        <!-- <div data-label="Example">
          <ul class="steps steps-lg">
            <li class="step-item" :class="popupState1 ? 'complete' : ''">
              <a href="#" class="step-link">
                <span class="step-number">1</span>
                <span class="step-title">
                  <span>ตู้ออกจากบริษัท</span>
                </span>
              </a>
            </li>
            <li class="step-item" :class="popupState2 ? 'complete' : ''">
              <a href="#" class="step-link">
                <span class="step-number">2</span>
                <span class="step-title">
                  <span>คืนตู้ท่าเรือ</span>
                </span>
              </a>
            </li>
            <li class="step-item" :class="popupState3 ? 'complete' : ''">
              <a href="#" class="step-link">
                <span class="step-number">3</span>
                <span class="step-title">
                  <span>เรือออกจากท่า</span>
                </span>
              </a>
            </li>
          </ul>
        </div> -->
        <div class="">
          <ul class="nav nav-line tx-bold" role="">
            <li class="nav-item">
              <a
                class="nav-link"
                :class="popupState === 1 ? 'active' : ''"
                data-toggle="tab"
                href="#step1"
                role="tab"
                aria-controls="step1"
                aria-selected="false"
                >(1). ตู้ออกจากบริษัท</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                :class="popupState === 2 ? 'active' : ''"
                data-toggle="tab"
                href="#step2"
                role="tab"
                aria-controls="step2"
                aria-selected="false"
                >(2). คืนตู้ที่ท่าเรือ</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                :class="popupState === 3 ? 'active' : ''"
                data-toggle="tab"
                href="#step3"
                role="tab"
                aria-controls="step3"
                aria-selected="false"
                >(3). เรือออกจากท่า</a
              >
            </li>
          </ul>
          <div class="tab-content mg-t-20" id="myTabContent5">
            <div
              class="tab-pane fade"
              :class="popupState === 1 ? 'active show' : ''"
              id="step1"
              role="tabpanel"
              aria-labelledby="step1-tab"
            >
              <div class="">
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for=""
                      >วันที่ตู้ออกจากบริษัท
                      <span class="tx-danger">*</span></label
                    >
                    <input
                      type="date"
                      class="form-control"
                      v-model="step1Date"
                      placeholder="วันที่"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label for="">เวลา <span class="tx-danger">*</span></label>
                    <input
                      type="time"
                      class="form-control"
                      v-model="step1Time"
                      placeholder="เวลา"
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for=""
                      >หมายเลขตู้ (Container No.)
                      <span class="tx-danger">*</span></label
                    >
                    <input
                      type="text"
                      v-model="step1ContainerNo"
                      class="form-control"
                      placeholder="หมายเลขตู้"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label for=""
                      >หมายเลขซีล (Seal No.)
                      <span class="tx-danger">*</span></label
                    >
                    <input
                      type="text"
                      v-model="step1SealNo"
                      class="form-control"
                      placeholder="หมายเลขซีล"
                    />
                  </div>
                </div>
              </div>
              <div class="d-sm-flex align-items-center justify-content-between">
                <div></div>
                <div class="tx-13 mg-t-15 mg-sm-t-0">
                  <button class="btn btn-primary" @click="doSave(iv, 1)">
                    บันทึก
                  </button>
                  <button
                    class="btn btn-dark mg-l-2"
                    @click="periodPopupClose()"
                  >
                    ปิดหน้าต่าง
                  </button>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              :class="popupState === 2 ? 'active show' : ''"
              id="step2"
              role="tabpanel"
              aria-labelledby="step2-tab"
            >
              <div class="">
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for=""
                      >วันที่คืนตู้ <span class="tx-danger">*</span></label
                    >
                    <input
                      type="date"
                      v-model="step2Date"
                      class="form-control"
                      placeholder="วันที่"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label for="">เวลา <span class="tx-danger">*</span></label>
                    <input
                      type="time"
                      v-model="step2Time"
                      class="form-control"
                      placeholder="เวลา"
                    />
                  </div>
                </div>
              </div>
              <div class="d-sm-flex align-items-center justify-content-between">
                <div></div>
                <div class="tx-13 mg-t-15 mg-sm-t-0">
                  <button class="btn btn-primary" @click="doSave(iv, 2)">
                    บันทึก
                  </button>
                  <button
                    class="btn btn-dark mg-l-2"
                    @click="periodPopupClose()"
                  >
                    ปิดหน้าต่าง
                  </button>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              :class="popupState === 3 ? 'active show' : ''"
              id="step3"
              role="tabpanel"
              aria-labelledby="step3-tab"
            >
              <div class="">
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for=""
                      >วันที่เรือออก <span class="tx-danger">*</span></label
                    >
                    <input
                      type="date"
                      v-model="step3Date"
                      class="form-control"
                      placeholder="วันที่"
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck2"
                        v-model="step3AllInvoice"
                      />
                      <label class="custom-control-label" for="customCheck2"
                        >อัพเดทวันที่เรือออกทุกตู้ในอินวอยนี้</label
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-sm-flex align-items-center justify-content-between">
                <div></div>
                <div class="tx-13 mg-t-15 mg-sm-t-0">
                  <button class="btn btn-primary" @click="doSave(iv, 3)">
                    บันทึก
                  </button>
                  <button
                    class="btn btn-dark mg-l-2"
                    @click="periodPopupClose()"
                  >
                    ปิดหน้าต่าง
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div></div>
        </div>
      </div>
      <!-- mail-compose-body -->
    </div>
    <!-- mail-compose-dialog -->
  </div>

  <div
    id="mailCompose2"
    class="mail-compose"
    :class="[
      popupCommentOpen ? ' show ' : ' d-none',
      popupCommentShrink ? ' shrink ' : '',
    ]"
    v-if="this.popupCommentDataInvoice !== null"
  >
    <div class="mail-compose-dialog">
      <div class="mail-compose-header">
        <h4 class="mail-compose-title fw-bold mg-l-10 text-white">
          {{ this.popupCommentDataInvoice.EPH_INVOICE_NO }}-{{
            this.popupCommentDataInvoice.EPH_SEQ_NO
          }}
        </h4>
        <nav class="nav nav-icon-only">
          <a
            v-if="!popupCommentShrink"
            id="mailComposeShrink"
            href="#"
            @click="popupCommentShrink = true"
            class="nav-link nav-link-shrink d-none d-lg-block"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-minimize-2"
            >
              <polyline points="4 14 10 14 10 20"></polyline>
              <polyline points="20 10 14 10 14 4"></polyline>
              <line x1="14" y1="10" x2="21" y2="3"></line>
              <line x1="3" y1="21" x2="10" y2="14"></line>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-maximize-2"
            >
              <polyline points="15 3 21 3 21 9"></polyline>
              <polyline points="9 21 3 21 3 15"></polyline>
              <line x1="21" y1="3" x2="14" y2="10"></line>
              <line x1="3" y1="21" x2="10" y2="14"></line>
            </svg>
          </a>
          <a
            v-if="popupCommentShrink"
            id="mailComposeShrink"
            href="#"
            @click="popupCommentShrink = false"
            class="nav-link nav-link-shrink d-none d-lg-block"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-minimize-2"
            >
              <polyline points="4 14 10 14 10 20"></polyline>
              <polyline points="20 10 14 10 14 4"></polyline>
              <line x1="14" y1="10" x2="21" y2="3"></line>
              <line x1="3" y1="21" x2="10" y2="14"></line>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-maximize-2"
            >
              <polyline points="15 3 21 3 21 9"></polyline>
              <polyline points="9 21 3 21 3 15"></polyline>
              <line x1="21" y1="3" x2="14" y2="10"></line>
              <line x1="3" y1="21" x2="10" y2="14"></line>
            </svg>
          </a>
          <a
            id="mailComposeClose"
            href="#"
            @click="(popupCommentOpen = false), (popupCommentShrink = false)"
            class="nav-link nav-link-close"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line></svg
          ></a>
        </nav>
      </div>
      <!-- mail-compose-header -->
      <div class="mail-compose-body h100vh">
        <div class="timeline-group tx-13">
          <div class="timeline-label">ประวัติรายการ</div>
          <div
            class="timeline-item"
            v-for="(c, index) in popupCommentDataComment"
            :key="index"
          >
            <div class="timeline-time">
              {{ c.TCM_DATETIME.substr(0, 10) }}<br />{{
                c.TCM_DATETIME.substr(11, 8)
              }}
            </div>
            <div class="timeline-body">
              <span class="">{{ c.TCM_COMPANY }}</span
              ><br />

              <p class="">
                {{ c.TCM_COMMENT }}
              </p>
            </div>
            <!-- timeline-body -->
          </div>
        </div>

        <hr class="mg-y-10" />
        <div class="form-row align-items-center">
          <div class="col-sm mg-b-10">คอมเมนต์เพิ่มเติม:</div>
        </div>
        <textarea
          class="form-control"
          rows="5"
          placeholder="ระบุรายละเอียด"
          v-model="popupCommentText"
        ></textarea>

        <div
          class="d-sm-flex align-items-center justify-content-between mg-t-25"
        >
          <div></div>
          <div class="tx-13 mg-t-15 mg-sm-t-0">
            <button class="btn btn-primary" @click="doSaveComment()">
              บันทึก
            </button>
          </div>
        </div>
      </div>

      <!-- mail-compose-body -->
    </div>
    <!-- mail-compose-dialog -->
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { useToast } from "vue-toastification";
import LobService from "@/services/lob";
import AuthService from "@/services/authen";
import $ from "jquery";
import moment from "moment";

import feather from "feather-icons";
const icons = Object.keys(feather.icons);

export default {
  data() {
    return {
      icons,
      isLoading: true,
      fullPage: true,
      inputTransport: null,
      inputDateStart: null,
      inputDateEnd: null,
      inputStatus: "ALL",
      selectTransport: null,
      selectDateStart: null,
      selectDateEnd: null,
      popupOpen: false,
      popupInvoiceNo: "",
      popupSeqNo: "",
      popupDataInvoice: "",

      popupCommentInvoiceNo: "",
      popupCommentSeqNo: "",
      popupCommentDataInvoice: null,
      popupCommentDataComment: null,
      popupCommentText: "",
      popupCommentOpen: false,
      popupCommentShrink: false,
      popupShrink: false,

      dataInvoice: null,
      selectTab: null,
      transportCompany: null,
      filterBox: false,
      userInfo: null,
      popupState: 0,

      step1Date: "",
      step1Time: "",
      step1ContainerNo: "",
      step1SealNo: "",
      step2Date: "",
      step2Time: "",
      step3Date: "",
      step3AllInvoice: false,
    };
  },
  service: null,
  components: {
    Loading,
  },
  setup() {
    // Get toast interface
    const toast = useToast();

    // Make it available inside methods
    return { toast };
  },
  computed: {
    currentUser() {
      return this.$store.state.authen.user;
    },
    loggedIn() {
      return this.$store.state.authen.status.loggedIn;
    },
    currentPath() {
      return this.$route.path;
    },
  },
  created() {
    this.getUserInfo();
    this.inputDateStart = new Date().toISOString().slice(0, 10);
    this.inputDateEnd = new Date().toISOString().slice(0, 10);
    this.inputTransport = "%";

    this.selectDateStart = this.inputDateStart;
    this.selectDateEnd = this.inputDateEnd;

    this.service = new LobService();
  },
  async mounted() {
    await this.refreshData();
    this.getTransport();
  },
  methods: {
    getUserInfo() {
      this.userInfo = AuthService.getInfo();
    },
    getComment(invoiceNo, seqNo) {
      this.service.getStuffingInvoiceComment(invoiceNo, seqNo).then((rez) => {
        console.log(rez);
        this.popupCommentDataComment = rez.data.dataComment;
      });
    },
    doSaveComment() {
      if (this.popupCommentText !== "" && this.popupCommentText !== null) {
        this.service
          .addNewComment(
            this.popupCommentDataInvoice,
            this.currentUser,
            this.popupCommentText
          )
          .then((rez) => {
            if (rez.data.isSuccess === 1) {
              this.toast.success("บันทึกข้อมูลสำเร็จ", {
                position: "top-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: false,
                rtl: false,
              });
              this.getComment(
                this.popupCommentDataInvoice.EPH_INVOICE_NO,
                this.popupCommentDataInvoice.EPH_SEQ_NO
              );
              this.popupCommentText = "";
            }
          });
      } else {
        this.toast.error("กรุณาระบุข้อความที่ต้องการคอมเมนต์", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: false,
          rtl: false,
        });
      }
    },
    getTransport() {
      this.service.getStuffingTransportCompany().then((rez) => {
        this.transportCompany = rez.data.dataTransport;
      });
    },
    periodCommentPopup(iv) {
      this.popupCommentOpen = true;
      this.popupCommentOpen = true;
      this.popupCommentDataInvoice = iv;
      this.getComment(iv.EPH_INVOICE_NO, iv.EPH_SEQ_NO);
      console.log(iv);
    },
    async periodRequest(iv) {
      this.Loading = true;
      console.log(iv);
      if (
        iv.TDP_TRANSPORT_CHANGE_REASON !== "" &&
        iv.TDP_TRANSPORT_CHANGE_REASON !== null
      ) {
        await this.service
          .requestChangePeriod(
            iv,
            this.currentUser,
            iv.TDP_TRANSPORT_CHANGE_REASON
          )
          .then((rez) => {
            if (rez.data.isSuccess === 1) {
              this.toast.success(
                "#" + rez.data.reqNo + " ส่งคำขอย้ายแผนโหลดสำเร็จ",
                {
                  position: "top-right",
                  timeout: 5000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: false,
                  closeButton: "button",
                  icon: false,
                  rtl: false,
                }
              );
              iv.TDP_TRANSPORT_CHANGE_REASON = null;
              this.popupOpen = false;
            }
          });
      } else {
        this.toast.error("กรุณาระบุเหตุผลในการขอย้ายแผน", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: false,
          rtl: false,
        });
      }
      this.Loading = true;
    },
    periodPopup(iv) {
      this.popupState = 1;
      this.popupOpen = true;
      this.popupInvoiceNo = iv.EPH_INVOICE_NO;
      this.popupSeqNo = iv.EPH_SEQ_NO;
      if (iv.TTG_GIT_ETD_ACTUAL) {
        this.popupState = 3;
      }
      if (iv.TTG_GIT_RETURN_LADEN) {
        this.popupState = 2;
      }
      if (iv.TTG_GIT_WH_DEPART) {
        this.popupState = 1;
      }
      if (iv.TTG_GIT_WH_DEPART !== null) {
        this.step1Date = iv.TTG_GIT_WH_DEPART.substr(0, 10);
        this.step1Time = iv.TTG_GIT_WH_DEPART.substr(11, 5);
      } else {
        this.step1Date = "";
        this.step1Time = "";
      }
      this.step1ContainerNo = iv.TTG_CONTAINER_NO;
      this.step1SealNo = iv.TTG_SEAL_NO;
      if (iv.TTG_GIT_RETURN_LADEN !== null) {
        this.step2Date = iv.TTG_GIT_RETURN_LADEN.substr(0, 10);
        this.step2Time = iv.TTG_GIT_RETURN_LADEN.substr(11, 5);
      } else {
        this.step2Date = "";
        this.step2Time = "";
      }
      if (iv.TTG_GIT_ETD_ACTUAL !== null) {
        this.step3Date = iv.TTG_GIT_ETD_ACTUAL.substr(0, 10);
      } else {
        this.step3Date = "";
      }
    },
    periodPopupClose() {
      this.popupInvoiceNo = "";
      this.popupCommentSeqNo = "";
      this.step1Date = "";
      this.step1Time = "";
      this.step1ContainerNo = "";
      this.step1SealNo = "";
      this.step2Date = "";
      this.step2Time = "";

      this.step3Date = "";
      this.step3AllInvoice = false;

      this.popupState = 1;
      this.popupOpen = false;
    },

    refreshTodayData() {
      this.inputDateStart = moment().format("YYYY-MM-DD");
      this.inputDateEnd = moment().format("YYYY-MM-DD");
      this.refreshData();
    },
    async refreshData() {
      this.isLoading = true;

      this.selectDateStart = this.inputDateStart;
      this.selectDateEnd = this.inputDateEnd;

      console.log("userinfo =>", this.userInfo.company);
      if (this.userInfo.company === "BTMT") {
        this.selectTransport = this.inputTransport;
      } else {
        this.selectTransport = this.userInfo.company;
      }

      if ($.fn.DataTable.isDataTable(".dt-table")) {
        $(".dt-table").DataTable().destroy();
      }

      await this.service
        .getStuffingPeriodTransportGit(
          this.selectDateStart,
          this.selectDateEnd,
          this.selectTransport,
          this.inputStatus
        )
        .then((rez) => {
          console.log(rez.data);
          this.dataInvoice = rez.data.dataInvoice;
        });

      $(".dt-table").DataTable({
        iDisplayLength: 20,
        bAutoWidth: false,
        bLengthChange: true,
        lengthMenu: [
          [10, 15, 20, 30, -1],
          [10, 15, 20, 30, "แสดงทั้งหมด"],
        ],
        oLanguage: {
          searchPlaceholder: "ค้นหา...",
          sInfo: "แสดงรายการที่ _START_ ถึง _END_ (จาก  _TOTAL_ รายการ)",
          sSearch: "",
          sInfoEmpty: "&nbsp;",
          sInfoFiltered: " ค้นหาจาก  _MAX_ รายการ",
          sZeroRecords: "ไม่มีข้อมูล",
          lengthMenu: "_MENU_",
        },
        language: {
          paginate: {
            previous: "&lt;",
            next: "&gt;",
          },
        },
        order: [[1, "asc"]],
      });
      $(".dataTables_length select").addClass("form-control");
      setTimeout(() => {
        this.isLoading = false;
      }, 300);
    },

    transportConfirm(iv, confirmFlag) {
      this.service
        .transportConfirm(iv, this.currentUser, confirmFlag)
        .then((response) => {
          console.log(response.data);
          this.refreshData();

          this.toast.success(
            "(" + iv.EPH_INVOICE_NO + "-" + iv.EPH_SEQ_NO + ") บันทึกสำเร็จ",
            {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: false,
              rtl: false,
            }
          );
        });
      //
    },
    async doSave(iv, state) {
      let isError = false;
      if (state === 1) {
        if (
          this.step1Date === null ||
          this.step1Date === undefined ||
          this.step1Date === ""
        ) {
          isError = true;
          this.toast.error("กรุณากรอกวันที่ตู้ออกจากบริษัท", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: false,
            rtl: false,
          });
        }
        if (
          this.step1Time === null ||
          this.step1Time === undefined ||
          this.step1Time === ""
        ) {
          isError = true;
          this.toast.error("กรุณากรอกเวลาตู้ออกจากบริษัท", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: false,
            rtl: false,
          });
        }
        if (this.step1ContainerNo === "") {
          isError = true;
          this.toast.error("กรุณากรอกหมายเลขตู้", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: false,
            rtl: false,
          });
        }
        if (this.step1SealNo === "") {
          isError = true;
          this.toast.error("กรุณากรอกหมายเลขซีล", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: false,
            rtl: false,
          });
        }
      }
      if (state === 2) {
        if (
          this.step2Date === null ||
          this.step2Date === undefined ||
          this.step2Date === ""
        ) {
          isError = true;
          this.toast.error("กรุณากรอกวันที่คืนตู้ที่ท่าเรือ", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: false,
            rtl: false,
          });
        }
        if (
          this.step2Time === null ||
          this.step2Time === undefined ||
          this.step2Time === ""
        ) {
          isError = true;
          this.toast.error("กรุณากรอกเวลาที่คืนตู้ที่ท่าเรือ", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: false,
            rtl: false,
          });
        }
      }
      if (state === 3) {
        if (
          this.step3Date === null ||
          this.step3Date === undefined ||
          this.step3Date === ""
        ) {
          isError = true;
          this.toast.error("กรุณากรอกวันที่เรือออก", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: false,
            rtl: false,
          });
        }
      }

      if (!isError) {
        let formData = new FormData();
        formData.append("state", state);
        formData.append("invoice_no", this.popupInvoiceNo);
        formData.append("seq_no", this.popupSeqNo);
        if (state === 1) {
          formData.append("step1_date", this.step1Date);
          formData.append("step1_time", this.step1Time);
          formData.append("step1_container_no", this.step1ContainerNo);
          formData.append("step1_seal_no", this.step1SealNo);
        }
        if (state === 2) {
          formData.append("step2_date", this.step2Date);
          formData.append("step2_time", this.step2Time);
        }
        if (state === 3) {
          formData.append("step3_date", this.step3Date);
          formData.append("step3_all_invoice", this.step3AllInvoice);
        }
        await this.service
          .transportGit(iv, this.currentUser, formData)
          .then((response) => {
            console.log(response.data);

            this.toast.success(
              "(" +
                this.popupInvoiceNo +
                "-" +
                this.popupSeqNo +
                ") บันทึกสำเร็จ",
              {
                position: "top-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: false,
                closeButton: "button",
                icon: false,
                rtl: false,
              }
            );

            this.refreshData();
          });
      }
    },
  },
};
</script>
<style scoped>
.input_w {
  max-width: 80px;
  text-align: right;
  height: 22px;
}
.table-sm th,
.table-sm td {
  padding: 1px 10px;
}
</style>
