<template>
  <div
    v-if="AppConfigs?.APP_VERSION && isVersionWarning"
    class="alert alert-solid alert-danger d-flex align-items-center justify-content-center"
    style="
      z-index: 2000;
      border-radius: 0;
      border: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
    "
  >
    ระบบตรวจพบว่าแอพมีการอัพเดทเวอร์ชั่น (เวอร์ชั่นล่าสุด
    {{ AppConfigs.APP_VERSION }})
    <div></div>
    <button
      type="button"
      class="btn btn-danger btn-icon px-3 mg-l-20"
      style="background: #900"
      @click="doReload()"
    >
      โหลดแอพใหม่
    </button>
  </div>
  <div v-if="currentUser">
    <Aside />
    <div class="content ht-100v pd-0">
      <!-- content-header -->
      <Header />
      <div class="content-body">
        <div class="container-fluid pd-x-0">
          <router-view />
        </div>
      </div>
    </div>
  </div>
  <!-- login form without navigator menus -->
  <div v-else><router-view /></div>
</template>
<script>
import Aside from "@/components/aside.vue";
import Header from "@/components/header.vue";
import { version } from "../package";
import axios from "axios";
import configs from "@/config/config";

export default {
  name: "App",
  components: {
    Aside,
    Header,
  },
  data() {
    return {
      isVersionWarning: false,
      AppConfigs: null,
      version,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.authen.user;
    },
    loggedIn() {
      return this.$store.state.authen.status.loggedIn;
    },
    currentPath() {
      return this.$route.path;
    },
  },
  async mounted() {
    await this.getConfigs();
    this.checkVersion();
  },
  methods: {
    async getConfigs() {
      await axios
        .get(configs.urlApi + "/master/get-config", {
          params: {},
        })
        .then((response) => {
          let data = response.data;
          console.log(data);
          this.AppConfigs = data.config;
        });
    },
    checkVersion() {
      if (this.AppConfigs.APP_VERSION !== version) {
        this.isVersionWarning = true;
      }
    },
    doReload() {
      this.$store.dispatch("authen/logout").then(() => {
        window.location.replace("/");
      });
    },
  },
};
</script>
<style>
html,
body,
#app {
  /* font-family: "DBHelvethaicaX", sans-serif; */
  font-family: "Sukhumvit Set", sans-serif;
  font-family: "Noto Sans Thai", sans-serif;
  /* line-height: 18px; */
}
.tx-thai {
  font-family: "Noto Sans Thai", sans-serif;
}
#app .text-normal .font-bold {
  font-family: "DBHelvethaicaX-Bd";
  font-weight: normal;
}
.form-control:focus {
  outline: none !important;
  border: 1px solid #999;
  box-shadow: 0 0 2px #efefef;
}
.swal2-container {
  font-family: "Sukhumvit Set", sans-serif !important;
  font-size: 15px !important;
}
/* .swal2-popup {
  background-color: #1c273c !important;
  color: #fff !important;
}
.swal2-popup * {
  color: #fff !important;
} */
.swal2-container ul {
  list-style: disc;
  margin-left: 0px;
  text-align: left !important;
}
.swal2-content {
  font-size: 16px !important;
}
.swal2-title {
  font-size: 24px !important;
  font-weight: 900 !important;
}
</style>
