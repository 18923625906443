<template>
  <div>
    <loading
      v-model:active="isLoading"
      :is-full-page="fullPage"
      color="#007070"
    ></loading>

    <div>
      <div class="d-sm-flex align-items-center justify-content-between mg-b-10">
        <div class="mg-b-10">
          <h5 class="mg-b-0">สต๊อกปัจจุบัน</h5>
          <p class="tx-color-03 mg-b-0">
            <span>อัพเดทล่าสุด:</span> {{ dataLastUpdate }}
          </p>
        </div>
        <div class="d-block"></div>
      </div>

      <div class="row row-xs mg-md-t-10">
        <!-- col -->
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 mg-t-10 mg-lg-t-0">
          <div class="row row-xs">
            <!-- col -->
            <div
              class="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-10 mg-lg-t-0"
            >
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-sm table-hover dt-table">
                      <thead>
                        <tr class="nowrap">
                          <td>ไซส์ยาง</td>
                          <td></td>
                          <td></td>

                          <td>ประเภท</td>
                          <td></td>
                          <td>เก่าสุด</td>
                          <td>ใหม่สุด</td>

                          <td>ทั้งหมด</td>
                          <td>จอง</td>
                          <td>ล็อค</td>
                          <td>คงเหลือ</td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody class="tx-roboto">
                        <tr
                          v-for="(b, index) in dTable"
                          :key="index"
                          class="nowrap cursor-pointer"
                        >
                          <td>{{ b.info.SIZE_SALECODE }}</td>
                          <td>{{ b.info.SIZE_SETCODE }}</td>
                          <td>{{ b.info.SIZE_PRODUCT_CODE }}</td>
                          <td>
                            {{
                              b.info.SIZE_PRODUCT_TYPE === "T"
                                ? "TIRE"
                                : b.info.SIZE_PRODUCT_TYPE === "C"
                                ? "TUBE"
                                : b.info.SIZE_PRODUCT_TYPE === "F"
                                ? "FLAP"
                                : b.info.SIZE_PRODUCT_TYPE === "A"
                                ? "SETTIRE"
                                : ""
                            }}
                          </td>
                          <td>{{ b.info.SIZE_PRODUCT_PATTERN }}</td>

                          <td>{{ b.info.FIRST_IN_DATE }}</td>
                          <td>{{ b.info.LAST_IN_DATE }}</td>
                          <td align="right">{{ b.info.TOTAL }}</td>
                          <td align="right">{{ b.info.ALLOCATE }}</td>
                          <td
                            align="right"
                            :class="b.info.LOCK > 0 ? 'tx-bold tx-pink' : ''"
                          >
                            {{ b.info.LOCK }}
                          </td>
                          <td align="right" class="tx-bold">
                            {{ b.info.AVAILABLE }}
                          </td>
                          <td>
                            <button
                              class="btn btn-xs btn-icon btn-dark mg-r-2 pd-y-0 pd-x-1"
                              @click="
                                popup(b.info.SIZE_SALECODE, b.info.SIZE_SETCODE)
                              "
                            >
                              <vue-feather
                                type="search"
                                class="mg-t-3"
                              ></vue-feather>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- col -->
            <!-- col -->
          </div>
          <!-- row -->
        </div>

        <!-- col -->
      </div>
      <!-- row -->
    </div>
  </div>
  <div
    id="mailCompose"
    class="mail-compose"
    :class="[popupOpen ? ' show ' : ' d-none', popupShrink ? ' shrink ' : '']"
    v-if="popupOpen"
  >
    <div class="mail-compose-dialog">
      <div class="mail-compose-header">
        <h4 class="mail-compose-title tx-white fw-bold mg-l-10 tx-15">
          {{ popupCode }}
        </h4>
        <nav class="nav nav-icon-only">
          <a
            id="mailComposeClose"
            href="#"
            @click="(popupOpen = false), (popupShrink = false)"
            class="nav-link nav-link-close"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line></svg
          ></a>
        </nav>
      </div>
      <!-- mail-compose-header -->
      <div class="mail-compose-body h100vh">
        <div class="row no-gutters rounded">
          <!-- col -->
          <div class="col-12 bg-white">
            <div class="ht-100p justify-content-centerx">
              <div class="table-responsive mg-t-20">
                <table class="table table-sm" v-if="popupData !== null">
                  <tbody class="tx-roboto">
                    <tr
                      v-for="(s, index) in popupData"
                      :key="index"
                      class="nowrap"
                    >
                      <td colspan="10">
                        <b>{{ s.size }}</b
                        ><br />
                        <table class="table table-xs tx-roboto">
                          <thead>
                            <tr class="tx-thai">
                              <th class="fit" align="center">ลำดับ</th>
                              <th class="fit" align="center">แอดเดรส</th>
                              <th class="fit" align="center">ล็อค</th>
                              <th class="fit" align="center">เก่าสุด</th>
                              <th class="fit" align="center">ใหม่สุด</th>
                              <th class="fit" align="center">ทั้งหมด</th>
                              <th class="fit" align="center">จอง</th>
                              <th class="fit" align="center">ล็อค</th>
                              <th class="fit" align="center">คงเหลือ</th>
                              <th align="center">หมายเหตุ</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(s2, index2) in s.listAddress"
                              :key="index2"
                              class="nowrap"
                            >
                              <td align="right">{{ index2 + 1 }}</td>
                              <td
                                :class="
                                  s2.Address_Hold_Flag === 'T'
                                    ? 'tx-danger'
                                    : ''
                                "
                              >
                                {{ s2.Address_Code }}
                              </td>
                              <td
                                class="tx-bold"
                                :class="
                                  s2.Address_Hold_Flag === 'T'
                                    ? 'tx-danger'
                                    : 'tx-success'
                                "
                              >
                                {{ s2.Address_Hold_Flag === "T" ? "Y" : "N" }}
                              </td>
                              <td>{{ s2.First_In_Date }}</td>
                              <td>{{ s2.Last_In_Date }}</td>
                              <td align="right">{{ s2.Total_Qty_Balance }}</td>
                              <td align="right">{{ s2.Allocate_Quantity }}</td>
                              <td
                                align="right"
                                :class="
                                  s2.Address_Hold_Flag === 'T'
                                    ? 'tx-bold tx-danger'
                                    : ''
                                "
                              >
                                {{
                                  s2.Address_Hold_Flag === "T"
                                    ? s2.Total_Qty_Balance
                                    : 0
                                }}
                              </td>
                              <td align="right" class="tx-bold">
                                {{
                                  s2.Address_Hold_Flag === "T"
                                    ? 0
                                    : s2.Available_Quantity
                                }}
                              </td>

                              <td class="tx-danger">{{ s2.Remark }}</td>
                            </tr>
                            <tr>
                              <td colspan="5"></td>
                              <td align="right" class="tx-bold">
                                {{ s.totalQty[0] }}
                              </td>
                              <td align="right" class="tx-bold">
                                {{ s.totalQty[1] }}
                              </td>
                              <td
                                align="right"
                                class="tx-bold"
                                :class="
                                  parseInt(s.totalQty[3]) > 0 ? 'tx-danger' : ''
                                "
                              >
                                {{ s.totalQty[3] }}
                              </td>
                              <td align="right" class="tx-bold">
                                {{ s.totalQty[2] }}
                              </td>

                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <button
                  @click="popupOpen = false"
                  class="th btn btn-dark btn-uppercase mg-r-4"
                >
                  ปิดหน้าต่าง
                </button>
              </div>
            </div>
          </div>
          <!-- col -->
        </div>
      </div>
      <!-- mail-compose-body -->
    </div>
    <!-- mail-compose-dialog -->
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { useToast } from "vue-toastification";
import $ from "jquery";
import axios from "axios";
import configs from "@/config/config";
import feather from "feather-icons";
//import moment from "moment";
const icons = Object.keys(feather.icons);
export default {
  data() {
    return {
      icons,
      isLoading: false,
      fullPage: true,
      dataLastUpdate: "",
      currentTab: "Y",
      nStep: [0, 0],
      dTable: null,
      popupOpen: false,
      popupShrink: false,
      popupData: null,
      popupCode: "",
      popupProcess: "",
    };
  },
  service: null,
  components: {
    Loading,
  },
  setup() {
    // Get toast interface
    const toast = useToast();
    // Make it available inside methods
    return { toast };
  },
  computed: {
    currentUser() {
      return this.$store.state.authen.user;
    },
    loggedIn() {
      return this.$store.state.authen.status.loggedIn;
    },
    currentPath() {
      return this.$route.path;
    },
    prefix() {
      return this.$store.state.prefix;
    },
  },
  created() {
    if (
      this.prefix === "" ||
      this.prefix === null ||
      this.prefix === undefined
    ) {
      window.location.replace("/");
    }
  },
  async mounted() {
    await this.refreshData();

    setTimeout(() => {
      this.refreshData();
    }, 300000);
  },
  methods: {
    async popup(salecode, setcode) {
      this.popupData = null;
      this.popupCode = salecode + setcode;
      this.popupOpen = true;
      this.isLoading = true;
      console.log("popupData = ", this.popupData);
      await axios
        .get(configs.urlApi + "/" + this.prefix.toLowerCase() + "/master", {
          params: {
            action: "stock-balance",
            salecode: salecode + setcode,
          },
        })
        .then((response) => {
          console.log(response.data);
          let data = response.data;
          this.popupData = data.stock;
          this.isLoading = false;
        });
      console.log(salecode + setcode);
      setTimeout(() => {
        this.isLoading = false;
      }, 300);
    },
    async refreshData() {
      this.dTable = [];
      this.nStep = [0, 0];
      if ($.fn.DataTable.isDataTable(".dt-table")) {
        $(".dt-table").DataTable().destroy();
      }
      this.isLoading = true;

      await axios
        .get(configs.urlApi + "/" + this.prefix.toLowerCase() + "/master", {
          params: {
            action: "stock-balance",
            salecode: "",
          },
        })
        .then((response) => {
          let data = response.data;
          console.log(data);

          this.dTable = data.stock;
          this.dataLastUpdate = data.current;
          this.isLoading = false;
        });
      $(".dt-table").DataTable({
        iDisplayLength: 20,
        bAutoWidth: false,
        bLengthChange: true,
        lengthMenu: [
          [10, 15, 20, 30, -1],
          [10, 15, 20, 30, "แสดงทั้งหมด"],
        ],
        oLanguage: {
          searchPlaceholder: "ค้นหา...",
          sInfo: "แสดงรายการที่ _START_ ถึง _END_ (จาก  _TOTAL_ รายการ)",
          sSearch: "",
          sInfoEmpty: "&nbsp;",
          sInfoFiltered: " ค้นหาจาก  _MAX_ รายการ",
          sZeroRecords: "ไม่มีข้อมูล",
          lengthMenu: "_MENU_",
        },
        language: {
          paginate: {
            previous: "&lt;",
            next: "&gt;",
          },
        },
      });
      $(".dataTables_length select").addClass("form-control");
      // $(".dt-table").DataTable().reload();
      setTimeout(() => {
        this.isLoading = false;
      }, 300);
    },
  },
};
</script>
<style scoped>
.input_w {
  max-width: 80px;
  text-align: right;
  height: 22px;
}
.table-sm th,
.table-sm td {
  padding: 1px 10px;
}
</style>
