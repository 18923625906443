<template>
  <div>
    <loading
      v-model:active="isLoading"
      :is-full-page="fullPage"
      color="#007070"
    ></loading>

    <div>
      <div class="d-sm-flex align-items-center justify-content-between mg-b-10">
        <div class="mg-b-10">
          <h5 class="mg-b-0">รายการล็อคไซส์ยาง</h5>
          <p class="tx-color-03 mg-b-0">
            <span>อัพเดทล่าสุด:</span> {{ dataLastUpdate }}
          </p>
        </div>
        <div class="d-block">
          <button
            type="button"
            class="btn btn-dark btn-icon px-3"
            @click="popupNew()"
          >
            เพิ่มไซส์ที่จะล็อค
          </button>
        </div>
      </div>

      <div class="row row-xs mg-md-t-10">
        <!-- col -->
        <div class="col-12 col-md-12 col-lg-12 col-xl-12 mg-t-10 mg-lg-t-0">
          <div class="row row-xs">
            <!-- col -->
            <div
              class="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-10 mg-lg-t-0"
            >
              <div class="card">
                <div class="card-body">
                  <ul class="nav nav-line" role="">
                    <li class="nav-item">
                      <a
                        class="nav-link cursor-pointer"
                        :class="currentTab === 'Y' ? 'active  tx-bold' : ''"
                        @click="(currentTab = 'Y'), refreshData()"
                        >ล็อคอยู่ &nbsp;&nbsp;<span
                          class="badge badge-primary"
                          >{{ nStep[0] }}</span
                        ></a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link cursor-pointer"
                        :class="currentTab === 'N' ? 'active  tx-bold' : ''"
                        @click="(currentTab = 'N'), refreshData()"
                        >ปลดล็อคแล้ว &nbsp;&nbsp;<span
                          class="badge badge-light"
                          >{{ nStep[1] }}</span
                        ></a
                      >
                    </li>
                  </ul>
                  <div class="table-responsive mg-t-20">
                    <table class="table table-sm table-hover dt-table">
                      <thead>
                        <tr class="nowrap">
                          <td>ลำดับ</td>

                          <td>ไซส์ยาง</td>
                          <td>วันที่เริ่มต้น</td>

                          <td>วันที่สิ้นสุด</td>
                          <td>สถานะ</td>
                          <td>หมายเหตุ</td>

                          <!-- <td>แก้ไขล่าสุดโดย</td> -->
                          <td>แก้ไขเมื่อ</td>
                          <td>ดำเนินการ</td>
                        </tr>
                      </thead>
                      <tbody class="tx-roboto">
                        <tr
                          v-for="(b, index) in dTable"
                          :key="index"
                          class="nowrap cursor-pointer"
                        >
                          <td>{{ index + 1 }}</td>
                          <td>{{ b.LC_SIZE }}</td>
                          <td>{{ b.LC_DATE_START }}</td>

                          <td class="tx-thai">{{ b.LC_DATE_END }}</td>
                          <td
                            class="tx-thai tx-white"
                            :class="
                              b.IS_LOCKED === 'Y' ? 'bg-pink' : 'bg-success'
                            "
                          >
                            {{
                              b.IS_LOCKED === "Y" ? "ล็อคอยู่" : "ปลดล็อคแล้ว"
                            }}
                          </td>
                          <td
                            class="tx-thai"
                            :class="
                              b.IS_LOCKED === 'Y' ? 'tx-pink' : 'tx-success'
                            "
                          >
                            {{ b.LC_REMARK }}
                          </td>

                          <!-- <td>{{ b.LC_UPDATE_USER }}</td>-->
                          <td class="tx-gray-900">{{ b.LC_UPDATE_TIME }}</td>

                          <td>
                            <button
                              v-if="b.IS_LOCKED === 'Y'"
                              class="btn btn-xs btn-icon btn-success mg-r-2 pd-y-0 pd-x-1"
                              @click="popupEdit(b)"
                            >
                              <vue-feather
                                type="edit-3"
                                class="mg-t-3"
                              ></vue-feather>
                            </button>
                            <button
                              v-if="b.IS_LOCKED === 'Y'"
                              class="btn btn-xs btn-icon btn-dark mg-r-2 pd-y-0 pd-x-1"
                              @click="doDelete(b)"
                            >
                              <vue-feather
                                type="x"
                                class="mg-t-3"
                              ></vue-feather>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- col -->
            <!-- col -->
          </div>
          <!-- row -->
        </div>

        <!-- col -->
      </div>
      <!-- row -->
    </div>
  </div>
  <div
    id="mailCompose"
    class="mail-compose"
    :class="[popupOpen ? ' show ' : ' d-none', popupShrink ? ' shrink ' : '']"
    v-if="popupOpen"
  >
    <div class="mail-compose-dialog">
      <div class="mail-compose-header">
        <h4 class="mail-compose-title tx-white fw-bold mg-l-10 tx-15">
          {{ popupCode }}
        </h4>
        <nav class="nav nav-icon-only">
          <a
            id="mailComposeClose"
            href="#"
            @click="(popupOpen = false), (popupShrink = false)"
            class="nav-link nav-link-close"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line></svg
          ></a>
        </nav>
      </div>
      <!-- mail-compose-header -->
      <div class="mail-compose-body h100vh">
        <div class="row no-gutters rounded">
          <!-- col -->
          <div class="col-12 bg-white">
            <div class="ht-100p justify-content-centerx">
              <form>
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for=""
                      >ไซส์ยาง <span class="tx-danger">*</span></label
                    ><input
                      type="text"
                      class="form-control tx-roboto"
                      placeholder="ไซส์ยาง"
                      v-model="popupData.LC_SIZE"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label for=""
                      >เริ่มต้น <span class="tx-danger">*</span></label
                    ><input
                      type="date"
                      class="form-control"
                      placeholder="เริ่มต้น"
                      v-model="popupData.LC_DATE_START"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label for=""
                      >สิ้นสุด <span class="tx-danger">*</span></label
                    ><input
                      type="date"
                      class="form-control"
                      placeholder="สิ้นสุด"
                      v-model="popupData.LC_DATE_END"
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="inputAddress"
                      >สาเหตุ / รายละเอียดการล็อค
                      <span class="tx-danger">*</span></label
                    ><textarea
                      type="text"
                      class="form-control"
                      placeholder="สาเหตุ / รายละเอียดการล็อค"
                      v-model="popupData.LC_REMARK"
                    ></textarea>
                  </div>
                </div>
              </form>
              <div>
                <button
                  id="btn-approve"
                  class="th btn btn-success btn-uppercase mg-r-4"
                  @click="doSave"
                >
                  บันทึกข้อมูล
                </button>
                <button
                  @click="popupOpen = false"
                  class="th btn btn-dark btn-uppercase mg-r-4"
                >
                  ปิดหน้าต่าง
                </button>
              </div>
            </div>
          </div>
          <!-- col -->
        </div>
      </div>
      <!-- mail-compose-body -->
    </div>
    <!-- mail-compose-dialog -->
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { useToast } from "vue-toastification";
import $ from "jquery";
import axios from "axios";
import configs from "@/config/config";
import feather from "feather-icons";
import moment from "moment";
const icons = Object.keys(feather.icons);
export default {
  data() {
    return {
      icons,
      isLoading: false,
      fullPage: true,
      dataLastUpdate: "",
      currentTab: "Y",
      nStep: [0, 0],
      dTable: null,
      popupOpen: false,
      popupShrink: false,
      popupData: null,
      popupCode: "",
      popupProcess: "",
    };
  },
  service: null,
  components: {
    Loading,
  },
  setup() {
    // Get toast interface
    const toast = useToast();
    // Make it available inside methods
    return { toast };
  },
  computed: {
    currentUser() {
      return this.$store.state.authen.user;
    },
    loggedIn() {
      return this.$store.state.authen.status.loggedIn;
    },
    currentPath() {
      return this.$route.path;
    },
    prefix() {
      return this.$store.state.prefix;
    },
  },
  created() {
    if (
      this.prefix === "" ||
      this.prefix === null ||
      this.prefix === undefined
    ) {
      window.location.replace("/");
    }
  },
  async mounted() {
    await this.refreshData();

    setTimeout(() => {
      this.refreshData();
    }, 300000);
  },
  methods: {
    async refreshData() {
      this.dTable = [];
      this.nStep = [0, 0];
      if ($.fn.DataTable.isDataTable(".dt-table")) {
        $(".dt-table").DataTable().destroy();
      }
      this.isLoading = true;

      await axios
        .get(configs.urlApi + "/" + this.prefix.toLowerCase() + "/master", {
          params: {
            action: "get-size-lock",
            dFilter: this.currentTab,
          },
        })
        .then((response) => {
          let data = response.data;
          console.log(data);
          this.nStep = data.counter;
          this.dTable = data.sizes;
          this.dataLastUpdate = data.current;
          this.isLoading = false;
        });
      $(".dt-table").DataTable({
        bAutoWidth: false,
        bLengthChange: true,
        lengthMenu: [
          [10, 15, 20, 30, -1],
          [10, 15, 20, 30, "แสดงทั้งหมด"],
        ],
        oLanguage: {
          sInfo: "แสดงรายการที่ _START_ ถึง _END_ (จาก  _TOTAL_ รายการ)",
          sSearch: "",
          sInfoEmpty: "&nbsp;",
          sInfoFiltered: " ค้นหาจาก  _MAX_ รายการ",
          sZeroRecords: "ไม่มีข้อมูล",
          lengthMenu: "_MENU_",
        },
        language: {
          paginate: {
            previous: "&lt;",
            next: "&gt;",
          },
        },
      });
      $(".dataTables_length select").addClass("form-control");
      // $(".dt-table").DataTable().reload();
      setTimeout(() => {
        this.isLoading = false;
      }, 300);
    },
    async popupEdit(b) {
      this.popupData = b;
      this.popupCode = "แก้ไขการล็อคไซส์ยาง";
      this.popupOpen = true;
      this.popupProcess = "update";
    },

    async popupNew() {
      this.popupData = {
        LC_SIZE: "",
        LC_DATE_START: moment().format("YYYY-MM-DD"),
        LC_DATE_END: moment().format("YYYY-MM-DD"),
        LC_REMARK: "",
      };
      this.popupCode = "เพิ่มไซส์ใหม่";
      this.popupOpen = true;
      this.popupProcess = "insert";
    },
    async doDelete(b) {
      this.$swal
        .fire({
          html: "ยืนยันการลบข้อมูลสำหรับไซส์ " + b.LC_SIZE + "?",
          icon: "question",
          showCancelButton: true,
          showConfirmButton: true,

          confirmButtonText: "ยืนยันการลบ",
          confirmButtonColor: "#10b759",
          cancelButtonText: "ยกเลิก",
          cancelButtonColor: "#df0007",
        })
        .then((result) => {
          if (result.isConfirmed) {
            let formData = new FormData();
            formData.append("lc_id", b.LC_ID);
            formData.append("user", this.currentUser);
            formData.append("do", "delete");
            formData.append("action", "size-lock-process");
            axios
              .post(
                configs.urlApi + "/" + this.prefix.toLowerCase() + "/master",
                formData
              )
              .then((res) => {
                console.log(res.data);
                this.toast.success(
                  "บันทึกข้อมูลสำเร็จ ออเดอร์จะถูกปลดล็อคประมาณ 10 นาทีหลังจากบันทึกข้อมูล",
                  {
                    position: "top-right",
                    timeout: 5000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: false,
                    rtl: false,
                  }
                );
                this.popupCode = "";
                this.popupDate = [];
                this.popupOpen = false;
                this.refreshData();
              });
          }
        });
    },
    async doSave() {
      let isError = false;
      if (!isError) {
        let formData = new FormData();
        formData.append("lc_size", this.popupData.LC_SIZE);
        formData.append("lc_date_start", this.popupData.LC_DATE_START);
        formData.append("lc_date_end", this.popupData.LC_DATE_END);
        formData.append("lc_remark", this.popupData.LC_REMARK);
        formData.append("lc_id", this.popupData.LC_ID);
        formData.append("user", this.currentUser);
        formData.append("do", this.popupProcess);
        formData.append("action", "size-lock-process");
        axios
          .post(
            configs.urlApi + "/" + this.prefix.toLowerCase() + "/master",
            formData
          )
          .then((res) => {
            console.log(res.data);
            this.toast.success(
              "บันทึกข้อมูลสำเร็จ ออเดอร์จะถูกล็อคประมาณ 10 นาทีหลังจากบันทึกข้อมูล",
              {
                position: "top-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: false,
                rtl: false,
              }
            );
            this.popupCode = "";
            this.popupDate = [];
            this.popupOpen = false;
            this.refreshData();
          });
      }
    },
  },
};
</script>
<style scoped>
.input_w {
  max-width: 80px;
  text-align: right;
  height: 22px;
}
.table-sm th,
.table-sm td {
  padding: 1px 10px;
}
@media only screen and (min-width: 600px) {
  .mail-compose-dialog {
    width: 80%;
  }
}
@media only screen and (min-width: 768px) {
  .mail-compose-dialog {
    width: 600px;
  }
}
</style>
