<template>
  <div class="content-header">
    <div class="content-search">
      <!-- <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-search"
      >
        <circle cx="11" cy="11" r="8"></circle>
        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
      </svg>
       <input
        type="search"
        class="form-control text-white"
        placeholder="ค้นหา..."
      /> -->
    </div>
    <nav class="nav">
      <a class="nav-link" @click="isShow = !isShow">
        <vue-feather type="align-left" class="w-24 h-24"></vue-feather>
      </a>
    </nav>
  </div>
  <div class="df-settings" :class="isShow ? 'show' : ''">
    <div class="df-settings-body">
      <div class="pd-t-20 text-right">
        <a @click="isShow = !isShow">
          <vue-feather type="x" class="w-24 h-24"></vue-feather>
        </a>
      </div>

      <div class="pd-y-20 bd-t">
        <label
          class="tx-sans tx-10 tx-uppercase tx-bold tx-spacing-1 tx-color-02 mg-b-15"
          >Quick Launch</label
        >
        <div class="row pd-x-15">
          <select class="form-control" @change="quickGoto">
            <option>กรุณาเลือกเมนูหรือฟังก์ชัน</option>
            <optgroup
              v-for="(m1, index1) in userMenu"
              :key="index1"
              :label="m1.MODULE_NAME"
            >
              <template v-for="(m2, index2) in m1.MODULE_CHILD" :key="index2">
                <option
                  :value="
                    m2.info.PERMISSION_IS_PARENT +
                    '|' +
                    m1.MODULE_CODE +
                    '|' +
                    m2.info.PERMISSION_URL
                  "
                >
                  {{ m2.info.PERMISSION_NAME }}
                </option>

                <template v-for="(m3, index3) in m2.child" :key="index3">
                  <option
                    :value="
                      m3.PERMISSION_IS_PARENT +
                      '|' +
                      m1.MODULE_CODE +
                      '|' +
                      m3.PERMISSION_URL
                    "
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;{{ m3.PERMISSION_NAME }}
                  </option></template
                >
              </template>
            </optgroup>
          </select>
        </div>
        <!-- row -->
      </div>
    </div>
    <!-- df-settings-body -->
  </div>
</template>
<script>
import AuthService from "../services/authen";
import feather from "feather-icons";
import axios from "axios";
import configs from "@/config/config";
import { version } from "../../package";

const icons = Object.keys(feather.icons);
export default {
  data() {
    return {
      isShow: false,
      icons,
      version,
      userInfo: null,
      userMenu: null,
      localPrefix: "",
      localCurrentPath: "",
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.authen.user;
    },
    loggedIn() {
      return this.$store.state.authen.status.loggedIn;
    },
    currentPath() {
      return this.$route.path;
    },
    prefix() {
      return this.$store.state.prefix;
    },
  },
  created() {
    this.localCurrentPath = this.$route.path;
  },

  mounted() {
    this.getUserInfo();
    this.getUserMenus();
    this.localPrefix = this.prefix;
  },
  methods: {
    async updateLog(p) {
      let uUser = this.currentUser;
      let uPath = p;
      let uPrefix = this.localPrefix;
      await axios
        .get(configs.urlApi + "/auth/login", {
          params: {
            action: "keeplogs",
            user: uUser,
            path: uPath,
            prefix: uPrefix,
          },
        })
        .then((response) => {
          let data = response.data;
          console.log(data);
        });
    },
    handleLogout() {
      this.$store.dispatch("authen/logout").then(() => {
        this.$router.push("/login");
      });
    },
    getUserInfo() {
      this.userInfo = AuthService.getInfo();

      // console.log(this.userInfo);
    },
    getUserMenus() {
      this.localCurrentPath = this.currentPath;
      return axios
        .get(configs.urlApi + "/master/get-menus", {
          params: {
            user: this.currentUser,
          },
        })
        .then((response) => {
          let data = response.data;
          this.userMenu = data.menus;

          console.log(data);
        });
    },
    quickGoto(e) {
      let isParent = e.target.value.substr(0, 1);
      let mModule = e.target.value.substr(2, 3);
      let mPath = e.target.value.substr(6, e.target.value.length - 6);
      console.log(e.target.value, isParent, mPath);
      if (isParent === "0") {
        this.$store.dispatch("setPrefix", mModule);
        this.$router.push(mPath);
        //window.location.replace(mPath);
      }
    },
  },
};
</script>
<style scoped>
.df-settings.show {
  right: 250px;
}
</style>
