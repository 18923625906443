import axios from "axios";
import configs from "../config/config";

export default class Lob {
  getGateStatus() {
    return axios.get(configs.urlLobApi, {
      params: {
        process: "stuffing-period-get-gate-status",
      },
    });
  }
  getStuffingPeriod(dateSelect, keyword) {
    return axios.get(configs.urlLobApi, {
      params: {
        d: dateSelect,
        keyword,
        process: "stuffing-period-get-lists",
      },
    });
  }

  getStuffingRequestWaitApprove() {
    return axios.get(configs.urlLobApi, {
      params: {
        process: "stuffing-period-get-request-wait-approve",
      },
    });
  }

  getStuffingPeriodGcos(dateSelect, keyword) {
    return axios.get(configs.urlLobApi, {
      params: {
        d: dateSelect,
        keyword,
        process: "stuffing-period-get-lists-gcos",
      },
    });
  }
  getStuffingPeriodTransport(dS, dE, transport, fStatus) {
    return axios.get(configs.urlLobApi, {
      params: {
        dS: dS,
        dE: dE,
        status: fStatus,
        transport: transport,
        process: "stuffing-period-get-lists-transport",
      },
    });
  }
  getStuffingPeriodTransportGit(dS, dE, transport, fStatus) {
    return axios.get(configs.urlLobApi, {
      params: {
        dS: dS,
        dE: dE,
        status: fStatus,
        transport: transport,
        process: "stuffing-period-get-lists-transport-git",
      },
    });
  }

  getStuffingPeriodPrepare(d) {
    return axios.get(configs.urlLobApi, {
      params: {
        d: d,

        process: "stuffing-period-get-lists-prepare",
      },
    });
  }
  getStuffingPeriodQueue(d) {
    return axios.get(configs.urlLobApi, {
      params: {
        d: d,

        process: "stuffing-period-get-lists-queue",
      },
    });
  }
  getStuffingPeriodDashboard(d) {
    return axios.get(configs.urlLobApi, {
      params: {
        d: d,

        process: "stuffing-period-get-lists-dashboard",
      },
    });
  }

  getStuffingInvoice(InvoiceNo) {
    return axios.get(configs.urlLobApi, {
      params: {
        iv: InvoiceNo,
        process: "stuffing-period-get-invoice",
      },
    });
  }
  getStuffingInvoiceGcos(InvoiceNo) {
    return axios.get(configs.urlLobApi, {
      params: {
        iv: InvoiceNo,
        process: "stuffing-period-get-invoice-gcos",
      },
    });
  }
  getStuffingInvoiceComment(InvoiceNo, seqNo) {
    return axios.get(configs.urlLobApi, {
      params: {
        iv: InvoiceNo,
        seqNo,
        process: "stuffing-period-get-invoice-comment",
      },
    });
  }
  getStuffingTransportCompany() {
    return axios.get(configs.urlLobApi, {
      params: {
        process: "stuffing-period-get-transport-company",
      },
    });
  }
  periodUpdate(iv, user) {
    let formData = new FormData();
    formData.append("process", "stuffing-period-update-tx-data-period");
    formData.append("loading_express", iv.TDP_LOADING_EXPRESS);
    formData.append("loading_date", iv.TDP_LOADING_DATE);
    formData.append("loading_period", iv.TDP_LOADING_PERIOD);
    formData.append("loading_cycut", iv.TDP_LOADING_CYCUT);
    formData.append("loading_comment", iv.TDP_LOADING_COMMENT);
    formData.append("invoice_no", iv.EPH_INVOICE_NO);
    formData.append("seq_no", iv.EPH_SEQ_NO);
    formData.append("user", user);
    formData.append("status", iv.TDP_STATUS);

    return axios.post(configs.urlLobApi, formData);
  }
  periodUpdateLock(iv, user, lock) {
    let formData = new FormData();
    formData.append("process", "stuffing-period-update-tx-data-period-lock");
    formData.append("loading_express", iv.TDP_LOADING_EXPRESS);
    formData.append("loading_date", iv.TDP_LOADING_DATE);
    formData.append("loading_period", iv.TDP_LOADING_PERIOD);
    formData.append("loading_cycut", iv.TDP_LOADING_CYCUT);
    formData.append("loading_comment", iv.TDP_LOADING_COMMENT);
    formData.append("invoice_no", iv.EPH_INVOICE_NO);
    formData.append("lock", lock);
    formData.append("seq_no", iv.EPH_SEQ_NO);
    formData.append("user", user);
    formData.append("status", "02L");

    return axios.post(configs.urlLobApi, formData);
  }
  transportConfirm(iv, user, isConfirm) {
    let formData = new FormData();
    formData.append("process", "stuffing-period-update-tx-transport-confirm");
    formData.append("invoice_no", iv.EPH_INVOICE_NO);
    formData.append("seq_no", iv.EPH_SEQ_NO);
    formData.append("transport_confirm", isConfirm);
    formData.append("user", user);
    formData.append("status", "01U" + isConfirm);

    return axios.post(configs.urlLobApi, formData);
  }
  transportGit(iv, user, formData) {
    formData.append("process", "stuffing-period-update-tx-transport-git");

    formData.append("user", user);

    return axios.post(configs.urlLobApi, formData);
  }
  tirePrepareUpdate(iv, user, flag) {
    let formData = new FormData();
    formData.append("process", "stuffing-period-update-tx-gcos-prepare");
    formData.append("invoice_no", iv.EPH_INVOICE_NO);
    formData.append("seq_no", iv.EPH_SEQ_NO);
    formData.append("gcos_no", iv.TGL_GCOS_NO);
    formData.append("gate", iv.TGP_GATE);
    formData.append("gate_l", iv.TGP_GATE_L);
    formData.append("gate_r", iv.TGP_GATE_R);
    formData.append("statusFlag", flag);
    formData.append("user", user);

    return axios.post(configs.urlLobApi, formData);
  }
  gcosProcessContainerClear(iv, user) {
    let formData = new FormData();
    formData.append("process", "stuffing-period-update-tx-gcos-process");
    formData.append("gcos_no", iv.TGL_GCOS_NO);
    formData.append("invoice_no", iv.EPH_INVOICE_NO);
    formData.append("seq_no", iv.EPH_SEQ_NO);
    formData.append("statusFlag", "containerClear");
    formData.append("user", user);

    return axios.post(configs.urlLobApi, formData);
  }
  gcosProcessContainerInOut(iv, user, statusFlag) {
    let formData = new FormData();
    formData.append("process", "stuffing-period-update-tx-gcos-process");
    formData.append("invoice_no", iv.EPH_INVOICE_NO);
    formData.append("seq_no", iv.EPH_SEQ_NO);
    formData.append("gcos_no", iv.TGL_GCOS_NO);
    formData.append("queue", iv.TGP_QUEUE);
    formData.append("cTimeIn", iv.TGP_CONTAINER_ARRIVED);
    formData.append("cTimeOut", iv.TGP_CONTAINER_LEAVE);
    formData.append("driver", iv.TGP_DRIVER_NAME);
    formData.append("statusFlag", statusFlag);
    formData.append("user", user);

    return axios.post(configs.urlLobApi, formData);
  }
  gcosProcessContainerStatus(iv, user, cOkNg) {
    let formData = new FormData();
    formData.append("process", "stuffing-period-update-tx-gcos-process");
    formData.append("invoice_no", iv.EPH_INVOICE_NO);
    formData.append("seq_no", iv.EPH_SEQ_NO);
    formData.append("gcos_no", iv.TGL_GCOS_NO);
    formData.append("cOkNg", cOkNg);
    formData.append("statusFlag", "containerStatus");
    formData.append("user", user);

    return axios.post(configs.urlLobApi, formData);
  }
  addNewComment(iv, user, txt) {
    let formData = new FormData();
    formData.append("process", "stuffing-period-update-tx-comment");
    formData.append("invoice_no", iv.EPH_INVOICE_NO);
    formData.append("seq_no", iv.EPH_SEQ_NO);
    formData.append("comment_text", txt);
    formData.append("comment_code", "INPUT");
    formData.append("comment_user", user);

    return axios.post(configs.urlLobApi, formData);
  }
  requestChangePeriod(iv, user, reason) {
    let formData = new FormData();
    formData.append("process", "stuffing-period-update-tx-req-change");
    formData.append("invoice_no", iv.EPH_INVOICE_NO);
    formData.append("seq_no", iv.EPH_SEQ_NO);
    formData.append("loading_date", iv.TDP_LOADING_DATE);
    formData.append("loading_period", iv.TDP_LOADING_PERIOD);
    formData.append("reason", reason);
    formData.append("requestor", user);

    return axios.post(configs.urlLobApi, formData);
  }
  approveChangePeriod(iv, user, status) {
    let formData = new FormData();
    formData.append("process", "stuffing-period-approve-tx-req-change");
    formData.append("invoice_no", iv.EPH_INVOICE_NO);
    formData.append("seq_no", iv.EPH_SEQ_NO);
    formData.append("req_no", iv.TTR_REQ_NO);
    formData.append("status", status);
    formData.append("approver", user);

    return axios.post(configs.urlLobApi, formData);
  }
  uploadDom(orderFile, user) {
    let formData = new FormData();
    formData.append("process", "stuffing-period-upload-dom-order");
    formData.append("file", orderFile);
    formData.append("user", user);

    return axios.post(configs.urlLobApi, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}
