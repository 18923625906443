<template>
  <aside class="aside aside-fixed minimize">
    <div class="aside-header">
      <router-link to="/" class="aside-logo"><span></span></router-link>

      <a href="" class="aside-menu-link">
        <i data-feather="menu"></i>
        <i data-feather="x"></i>
      </a>
    </div>
    <div class="aside-body">
      <div class="aside-loggedin">
        <div class="d-flex align-items-center justify-content-start">
          <a href="#" class="avatar"
            ><span class="avatar-initial avatar-online rounded-circle">
              {{ currentUser.toString().substr(0, 1) }}
            </span></a
          >
        </div>
        <div class="aside-loggedin-user" v-if="userInfo">
          <a
            href="#loggedinMenu"
            class="d-flex align-items-center justify-content-between mg-b-2"
            data-toggle="collapse"
          >
            <h6 class="tx-semibold mg-b-0">{{ userInfo.alias }}</h6>
            <i data-feather="chevron-down"></i>
          </a>
          <p class="tx-color-03 tx-12 mg-b-0">{{ userInfo.code }}</p>
        </div>
        <div class="collapse" id="loggedinMenu">
          <ul class="nav nav-aside mg-b-0">
            <li class="nav-item">
              <a href="#" @click="handleLogout" class="nav-link">
                <i data-feather="log-out"></i>
                <span>ออกจากระบบ</span></a
              >
            </li>
          </ul>
        </div>
      </div>
      <!-- aside-loggedin -->
      <ul class="nav nav-aside">
        <li class="nav-label">Dashboard</li>
        <li
          class="nav-item"
          :class="[
            currentPath === '' ||
            currentPath === '/index' ||
            currentPath === '/'
              ? 'active'
              : '',
          ]"
        >
          <router-link
            to="/"
            class="nav-link"
            @click="(localCurrentPath = '/'), resetPrefix()"
            ><vue-feather type="airplay"></vue-feather> <span>หน้าหลัก</span>
          </router-link>
        </li>
      </ul>
      <ul class="nav nav-aside" v-if="localPrefix">
        <li class="nav-label mg-t-15">ACTIONS</li>
        <li
          v-for="(m, index) in userMenu"
          :key="index"
          :class="[
            m.info.PERMISSION_LEVEL === 1 ? 'nav-item' : '',
            currentPath === m.info.PERMISSION_URL &&
            m.info.PERMISSION_LEVEL === 1
              ? 'active'
              : '',
            m.info.PERMISSION_IS_PARENT === 1 ? 'with-sub' : '',
            currentPath.search(m.info.PERMISSION_URL) !== -1 &&
            m.info.PERMISSION_LEVEL === 1 &&
            m.info.PERMISSION_IS_PARENT === 1
              ? 'active show'
              : '',
          ]"
        >
          <router-link
            v-if="m.info.PERMISSION_LEVEL === 1"
            :to="m.info.PERMISSION_URL"
            class="nav-link"
            @click="
              (localCurrentPath = m.info.PERMISSION_URL),
                this.updateLog(m.info.PERMISSION_URL)
            "
            ><vue-feather :type="m.info.PERMISSION_ICON"></vue-feather>
            <span>{{ m.info.PERMISSION_NAME }}</span>
          </router-link>
          <!-- <a
            href
            v-if="
              m.info.PERMISSION_LEVEL === 1 && m.info.PERMISSION_IS_PARENT === 1
            "
            class="nav-link cursor-pointer"
            @click="localCurrentPath = m.info.PERMISSION_URL"
            ><vue-feather :type="m.info.PERMISSION_ICON"></vue-feather>
            <span>{{ m.info.PERMISSION_NAME }}</span>
          </a> -->

          <ul v-if="m.info.PERMISSION_IS_PARENT === 1">
            <li
              v-for="(m2, index2) in m.child"
              :key="index2"
              :class="[currentPath === m2.PERMISSION_URL ? 'active' : '']"
            >
              <router-link
                :to="m2.PERMISSION_URL"
                @click="
                  (localCurrentPath = m2.PERMISSION_URL),
                    this.updateLog(m2.PERMISSION_URL)
                "
              >
                {{ m2.PERMISSION_NAME }}
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
      <ul class="nav nav-aside">
        <li class="nav-label mg-t-15">User Interface</li>
        <li class="nav-item">
          <a href="#" @click="handleLogout" class="nav-link"
            ><vue-feather type="lock"></vue-feather> <span>ออกจากระบบ</span>
          </a>
        </li>

        <li class="nav-label mg-t-15">VERSION {{ version }}</li>
      </ul>
    </div>
  </aside>
</template>
<script>
import { version } from "../../package";
import AuthService from "../services/authen";
import feather from "feather-icons";
import axios from "axios";
import configs from "@/config/config";

const icons = Object.keys(feather.icons);
export default {
  data() {
    return {
      icons,
      version,
      userInfo: null,
      userMenu: null,
      localPrefix: "",
      localCurrentPath: "",
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.authen.user;
    },
    loggedIn() {
      return this.$store.state.authen.status.loggedIn;
    },
    currentPath() {
      return this.$route.path;
    },
    prefix() {
      return this.$store.state.prefix;
    },
  },
  created() {
    this.localCurrentPath = this.$route.path;
  },

  mounted() {
    this.getUserInfo();
    this.getUserMenus();
    this.localPrefix = this.prefix;
  },
  methods: {
    async updateLog(p) {
      let uUser = this.currentUser;
      let uPath = p;
      let uPrefix = this.localPrefix;
      await axios
        .get(configs.urlApi + "/auth/login", {
          params: {
            action: "keeplogs",
            user: uUser,
            path: uPath,
            prefix: uPrefix,
          },
        })
        .then((response) => {
          let data = response.data;
          console.log(data);
        });
    },
    resetPrefix() {
      this.localPrefix = null;
      this.$store.dispatch("setPrefix", null);
      this.$store.dispatch("clearPrefix");
    },
    handleLogout() {
      this.$store.dispatch("authen/logout").then(() => {
        this.$router.push("/login");
      });
    },
    getUserInfo() {
      this.userInfo = AuthService.getInfo();

      // console.log(this.userInfo);
    },
    getUserMenus() {
      this.localCurrentPath = this.currentPath;
      AuthService.getMenu(this.prefix, this.currentUser).then((data) => {
        if (data.result === 1) {
          this.userMenu = data.permission;
        }
      });
    },
  },
};
</script>
<style scoped>
.nav-label {
  color: #97a3b9;
}
</style>
