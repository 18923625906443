<template>
  <div>
    <loading
      v-model:active="isLoading"
      :is-full-page="fullPage"
      color="#cd8444"
    ></loading>
    <div class="">
      <div class="row row-xs">
        <!-- col -->
        <div
          class="col-12 col-md-12 col-lg-10 col-xl-8 col-xxl-6 mg-t-10 mg-lg-t-0"
        >
          <div class="row row-xs">
            <!-- col -->
            <div
              class="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-10 mg-lg-t-0"
            >
              <div class="card">
                <div
                  class="bd-b-0 pd-t-20 pd-lg-t-20 pd-l-20 pd-lg-l-20 d-flex flex-column flex-sm-row align-items-sm-start justify-content-sm-between"
                >
                  <div>
                    <h4 class="mg-b-5 text-primary tx-bold">
                      รายงานสถานะการขนส่ง
                    </h4>
                    <p class="mg-b-0">
                      กรุณาเลือกประเภทรายงานและระบุช่วงเวลาในการออกรายงาน
                    </p>
                  </div>
                </div>
                <div class="card-body">
                  <div>
                    <div class="tab-content">
                      <div class="tab-pane fade show active mg-t-20">
                        <div>
                          <div class="form-row">
                            <div class="form-group col-md-12">
                              <label for=""
                                >ประเภทรายงาน
                                <span class="tx-danger">*</span></label
                              >
                              <select class="form-control" v-model="rType">
                                <option value="">กรุณาเลือกประเภทรายงาน</option>

                                <option value="3pl-git">
                                  รายงานสถานะการขนส่ง
                                </option>
                              </select>
                            </div>
                          </div>
                          <div
                            class="form-row"
                            v-if="userInfo?.company === 'BTMT'"
                          >
                            <div class="form-group col-md-12">
                              <label for=""
                                >บริษัทขนส่ง
                                <span class="tx-danger">*</span></label
                              >
                              <select class="form-control" v-model="rCompany">
                                <option value="">กรุณาเลือกบริษัทขนส่ง</option>
                                <option value="%">แสดงทั้งหมด</option>
                                <option
                                  v-for="(t, index) in transportCompany"
                                  :key="index"
                                  :value="t.C_CODE"
                                >
                                  {{ t.C_CODE }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="form-group col-md-6">
                              <label for=""
                                >ช่วงวันที่ (เริ่มต้น)
                                <span class="tx-danger">*</span></label
                              >
                              <input
                                type="date"
                                class="form-control"
                                v-model="dStart"
                              />
                            </div>

                            <div class="form-group col-md-6">
                              <label for=""
                                >ถึงวันที่
                                <span class="tx-danger">*</span></label
                              >
                              <input
                                type="date"
                                class="form-control"
                                v-model="dEnd"
                              />
                            </div>
                          </div>
                          <div class="form-row">
                            <div class="form-group col-md-6">
                              <button
                                class="th btn btn-dark btn-uppercase mg-r-4"
                                @click="downloadReport()"
                              >
                                ดาวน์โหลดรายงาน
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- row -->
            </div>
          </div>
        </div>
        <div class="mg-t-20"></div>
      </div>
    </div>

    <!-- col -->
  </div>
  <!-- row -->
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { useToast } from "vue-toastification";
import configs from "@/config/config";
import moment from "moment";
import LobService from "@/services/lob";
import AuthService from "@/services/authen";

export default {
  data() {
    return {
      isLoading: false,
      fullPage: true,
      sitePrefix: "",
      rType: "",
      rCompany: "",
      dStart: null,
      dEnd: null,
      timer: "",
      filterStatus: [],
      transportCompany: null,
      userInfo: null,
    };
  },
  service: null,
  components: {
    Loading,
  },
  setup() {
    // Get toast interface
    const toast = useToast();

    // Make it available inside methods
    return { toast };
  },
  computed: {
    currentUser() {
      return this.$store.state.authen.user;
    },
    loggedIn() {
      return this.$store.state.authen.status.loggedIn;
    },
    currentPath() {
      return this.$route.path;
    },
    prefix() {
      return this.$store.state.prefix;
    },
  },
  created() {
    if (
      this.prefix === "" ||
      this.prefix === null ||
      this.prefix === undefined
    ) {
      window.location.replace("/");
    }
    this.getUserInfo();
    this.dStart = moment().format("YYYY-MM-01");
    this.dEnd = moment().format("YYYY-MM-DD");
    this.service = new LobService();
  },
  async mounted() {
    this.sitePrefix = await this.prefix;
    this.getTransport();
  },
  methods: {
    getUserInfo() {
      this.userInfo = AuthService.getInfo();
    },
    getTransport() {
      this.service.getStuffingTransportCompany().then((rez) => {
        this.transportCompany = rez.data.dataTransport;
      });
    },
    downloadReport() {
      if (this.userInfo.company !== "BTMT") {
        this.rCompany = this.userInfo.company;
      }
      let isError = false;
      if (this.rType === null || this.rType === "") {
        this.toast.error("กรุณาเลือกประเภทรายงาน", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: false,
          rtl: false,
        });
        isError = true;
      }
      if (this.rCompany === null || this.rCompany === "") {
        this.toast.error("กรุณาเลือกบริษัทขนส่ง", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: false,
          rtl: false,
        });
        isError = true;
      }

      if (this.dStart === null || this.dStart === "") {
        this.toast.error("กรุณาระบุวันที่เริ่มต้น", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: false,
          rtl: false,
        });
        isError = true;
      }
      if (this.dEnd === null || this.dEnd === "") {
        this.toast.error("กรุณาระบุวันที่สิ้นสุด", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: false,
          rtl: false,
        });
        isError = true;
      }

      if (!isError) {
        let url =
          configs.urlLobApi +
          "?process=bship-" +
          this.rType.toLowerCase() +
          "&company=" +
          this.rCompany.toLowerCase() +
          "&dStart=" +
          this.dStart +
          "&dEnd=" +
          this.dEnd;
        console.log(url);
        window.open(url);
      }
    },
  },
};
</script>
<style scoped></style>
