import { createRouter, createWebHistory } from "vue-router";
import Index from "../pages/index.vue";
// import Blank from "../pages/blank.vue";
import Login from "../pages/authen/login.vue";
import whsOutboundImport from "../pages/modules/warehouse/outbound/import.vue";
import whsOutboundCondition from "../pages/modules/warehouse/outbound/condition.vue";
import whsInventoryStock from "../pages/modules/warehouse/inventory/stock.vue";
import whsReportOutbound from "../pages/modules/warehouse/reports/outbound.vue";

import exsMasterSizeLock from "../pages/modules/shipping/master/size-lock.vue";

import lob3PLConfirm from "../pages/modules/loadingboard/3pl/3pl-confirm.vue";
import lob3PLGit from "../pages/modules/loadingboard/3pl/3pl-git.vue";
import lob3PLReportOutbound from "../pages/modules/loadingboard/3pl/3pl-report-outbound.vue";

const routes = [
  {
    path: "/",
    props: true,
    component: Index,
  },

  {
    path: "/login",
    component: Login,
  },
  {
    path: "/warehouse/outbound-import",
    component: whsOutboundImport,
  },
  {
    path: "/warehouse/outbound-condition",
    component: whsOutboundCondition,
  },
  {
    path: "/warehouse/inventory",
    component: whsInventoryStock,
  },
  {
    path: "/warehouse/report-outbound",
    component: whsReportOutbound,
  },
  //------------------------------------------
  {
    path: "/shipping/master-size-lock",
    component: exsMasterSizeLock,
  },
  //------------------------------------------
  {
    path: "/lob/3pl-confirm",
    component: lob3PLConfirm,
  },
  {
    path: "/lob/3pl-git",
    component: lob3PLGit,
  },
  {
    path: "/lob/3pl-report-outbound",
    component: lob3PLReportOutbound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  // console.log(to.path);
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  // keep current path
  localStorage.setItem("path", to.path);

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});
export default router;
